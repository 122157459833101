import { connect } from "react-redux";
import React, { Component } from "react";
import { OnboardValues } from "../../../store/actions/Auth";

class OnBoard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            onBoard: false,
            isLoadingOnBoard: false,
        }
    }

    onBoardEvent = (item) => {
        this.setState({ isLoadingOnBoard: true })
        this.props.OnboardValues({ "uuid": item })
    }

    render() {
        const { isLoadingOnBoard } = this.state;
        const { userDetails } = this.props
        return (
            <>
                <div className="inner-box">
                    <div className="logo-area">
                        <img src={require("../../../static/images/TEX-Wallet.jpeg")} alt="" />
                    </div>
                    <div className="content-box">
                        <h2>Onboard</h2>
                        {userDetails['step'] !== 'onboard' && <h5>Connect Via</h5>}
                        <div className="verfiy-box">
                            {userDetails['step'] !== 'onboard' && <img src={require("../../../static/images/steps/1.png")} alt="" />}
                            {userDetails['step'] !== 'onboard'
                                ? isLoadingOnBoard === false
                                    ? <div className="overlay-box overlay-box-onboard" onClick={() => this.onBoardEvent(this.props.userDetails['uuid'])}>
                                        <img src={require("../../../static/images/steps/5.png")} alt="" />
                                        <h3>Onboard</h3>
                                    </div>
                                    : <div className="overlay-box overlay-onboard-waiting">
                                        <i className="waiting-loader fas fa-spinner fa-spin"></i>
                                        <h3> Wait...</h3>
                                    </div>
                                : <div className="form-box">
                                    <form>
                                        <div className="group-form text-left">
                                            <button onClick={() => this.props.handleNext()}>Next <i className="icon"><img src={require("../../../static/images/steps/7.png")} alt="" /></i></button>
                                        </div>
                                    </form>
                                </div>
                            }
                        </div>
                        <p>Aenean id porttitor purus, et cursus augue. Vestibulum nibh justo, molestie in dictum in, posuere non tellus. Etiam dolor purus, porta eut</p>
                    </div>
                </div>
            </>
        );
    }
}

const mapDispatchToProps = {
    OnboardValues,
};

const mapStateToProps = ({ Auth }) => {
    let { userDetails, onBoard, } = Auth;
    return { userDetails, onBoard };
};

export default connect(mapStateToProps, mapDispatchToProps)(OnBoard);