import { Fragment } from 'react';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import Navbar from '../../components/Navbar';

import "./index.css";
import NewKey from './NewKey';
import AllKeys from './AllKeys';
import AppLoader from "../../components/AppLoader";
import { getAPIKeys, APIKeysLoader } from "../../store/actions/Auth";

class APIKeys extends Component {

  constructor(props) {
    super(props);
    this.state = {
    };
    props.getAPIKeys();
    props.APIKeysLoader(true);
  }

  render() {
    let { lightMode, apiKeyLoader, apiKeys } = this.props;
    return (
      <>
        <div className={`page-wrapper-inner ${lightMode ? 'light' : 'dark'}`}>
          <Navbar {...this.props} />
          {apiKeyLoader
            ? <div className='api-loader'>
              <AppLoader message="Loading..." />
            </div>
            : <Fragment>
              {apiKeys.length > 0
                ? <AllKeys {...this.props} />
                : <NewKey {...this.props} />
              }
            </Fragment>
          }
        </div>
      </>
    )
  }
}

const mapDispatchToProps = { getAPIKeys, APIKeysLoader, };
const mapStateToProps = ({ Auth }) => {
  let { lightMode, apiKeyLoader, apiKeys } = Auth;
  return { lightMode, apiKeyLoader, apiKeys };
};
export default connect(mapStateToProps, mapDispatchToProps)(APIKeys);
