import { connect } from "react-redux";
import React, { Component } from "react";
import { CreateCertificate } from "../../../store/actions/Auth";


class Certificate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isCertificate: false,
            certificateLoading: false
        }
    }

    onCreated = () => {
        this.setState({ certificateLoading: true })
        var DataValue = {
            'uuid': this.props.userDetails['uuid'],
            "attributes": [
                {
                    "name": "personPrimaryEmail",
                    "value": this.props.userDetails['email']
                }, {
                    "name": "personFirstName",
                    "value": this.props.userDetails['firstName']
                }, {
                    "name": "personLastName",
                    "value": this.props.userDetails['lastName']
                }, {
                    "name": "personGender",
                    "value": this.props.userDetails['gender']
                }, {
                    "name": "personDateOfBirth",
                    "value": this.props.userDetails['dob']
                }, {
                    "name": "personPrimaryFacePhoto",
                    "value": this.props.userDetails['kyc']['selfie']
                }, {
                    "name": "personPrimaryPhone",
                    "value": this.props.userDetails['phone']
                }, {
                    "name": "personPrimaryCity",
                    "value": this.props.userDetails['city']
                }, {
                    "name": "personPrimaryCountry",
                    "value": this.props.userDetails['country']
                }, {
                    "name": "personPrimaryPostalCode",
                    "value": this.props.userDetails['postalCode']
                }, {
                    "name": "personPrimaryAddress1",
                    "value": this.props.userDetails['address']
                }, {
                    "name": "personPrimaryAddress2",
                    "value": this.props.userDetails['address2']
                }
            ]
        }
        console.log('DataValue',DataValue)
        this.props.CreateCertificate(DataValue)
    }

    componentWillReceiveProps({ userDetails, createCertificate }) {
        if (userDetails['step'] === 'certificate') { this.setState({ certificateLoading: false, isCertificate: true }) }
        else { this.setState({ certificateLoading: false, isCertificate: false }) }
        if (createCertificate) { this.setState({ certificateLoading: false, isCertificate: false }) }
    };

    render() {
        const { isCertificate, certificateLoading } = this.state
        return (
            <>
                <div className="inner-box">
                    <div className="logo-area">
                        <img src={require("../../../static/images/TEX-Wallet.jpeg")} alt="" />
                    </div>
                    <div className="content-box">
                        {!isCertificate && <h2>Create Certificate</h2>}
                        {isCertificate == false
                            ? certificateLoading == false
                                ? <div className="verfiy-box" onClick={() => this.onCreated()}>
                                    <img src={require("../../../static/images/steps/9.png")} alt="" />
                                    <div className="overlay-box overlay-box-create">
                                        <img src={require("../../../static/images/steps/8.png")} alt="" />
                                        <h3>Create</h3>
                                    </div>
                                </div>
                                : <div className="verfiy-box">
                                    <img src={require("../../../static/images/steps/9.png")} alt="" />
                                    <div className="overlay-box overlay-create-waiting">
                                        <i className="waiting-loader fas fa-spinner fa-spin"></i>
                                        <h3>Wait...</h3>
                                    </div>
                                </div>
                            : <div class="congrets-box">
                                <div class="congrets-area">
                                    <img src={require("../../../static/images/steps/10.png")} alt="" />
                                    <h2>Congratulations</h2>
                                    <h3 className='text-white'>Certificate of <br /> Authentication</h3>
                                </div>
                            </div>
                        }
                        <p className="text">Aenean id porttitor purus, et cursus augue. Vestibulum nibh justo, molestie in dictum in, posuere non tellus. Etiam dolor purus, porta eut</p>
                    </div>
                </div>
            </>
        );
    }
}

const mapDispatchToProps = {
    CreateCertificate
};

const mapStateToProps = ({ Auth }) => {
    let { userDetails, createCertificate } = Auth;
    return { userDetails, createCertificate };
};
export default connect(mapStateToProps, mapDispatchToProps)(Certificate);