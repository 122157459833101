import { connect } from 'react-redux';
import EventBus from "eventing-bus";
import React, { Component } from 'react';
import { Button, ModalFooter } from 'reactstrap';

import NumberFormat from '../../components/NumberFormat';
import { placeOrder } from '../../store/actions/Trading';

class BuySell extends Component {

  constructor(props) {
    super(props);

    let pair = props.selectedCoin.split('/');
    this.state = {
      altCoin: pair[0],
      masterCoin: pair[1],
      priceCoin: props.selectedCoin,
      selectedCoin: props.selectedCoin,

      buyData: {
        price: '',
        amount: '',
        total: '',
        loader: false,
        checked: '0'
      },
      sellData: {
        price: '',
        amount: '',
        total: '',
        loader: false,
        checked: '0'
      },
    };
  }

  componentDidMount() {
    setTimeout(() => {
      let { coinStats } = this.props;
      let { buyData, sellData } = this.state;
      buyData['price'] = coinStats['price'];
      sellData['price'] = coinStats['price'];
      this.setState({ buyData, sellData });
    }, 1000);

    EventBus.on('spot:order:placed', data => {
      let { buyData, sellData } = this.state;
      if (data['status'] == 'success') {
        buyData['amount'] = '';
        buyData['total'] = '';
        buyData['checked'] = '0';
        buyData['loader'] = false;

        sellData['amount'] = '';
        sellData['total'] = '';
        sellData['checked'] = '0';
        sellData['loader'] = false;

        this.setState({ buyData, sellData });
      } else {
        buyData['loader'] = false;
        sellData['loader'] = false;
        this.setState({ buyData, sellData });
      }
    });
  }

  componentWillReceiveProps = ({ selectedCoin, coinStats }) => {
    /* Set New Pair Prices When coin changes */
    if (coinStats['symbol'] != this.state.priceCoin) {
      let { buyData, sellData } = this.state;
      buyData['price'] = coinStats['price'];
      sellData['price'] = coinStats['price'];
      this.setState({ buyData, sellData, priceCoin: coinStats['symbol'] });
    }

    if (selectedCoin) {
      let pair = selectedCoin.split('/');
      this.setState({ altCoin: pair[0], masterCoin: pair[1], selectedCoin });
    }
  }

  /* Place Buy Order */
  handleBuyChange = (e) => {
    const { buyData } = this.state;
    buyData[e.target.name] = e.target.value;
    this.setState({ buyData });

    if (buyData['price'] && buyData['amount'])
      buyData['total'] = parseFloat(buyData['price'] * buyData['amount'] || 0).toFixed(8);
    else buyData['total'] = '';
  }

  submitBuyData = () => {
    let { buyData, selectedCoin } = this.state;

    buyData['loader'] = true;
    buyData['type'] = 'limit';
    buyData['action'] = 'buy';
    buyData['pair'] = selectedCoin;
    this.props.placeOrder(buyData);
  }

  /* Place Sell Order */
  handleSellChange = (e) => {
    const { sellData } = this.state;
    sellData[e.target.name] = e.target.value;

    if (sellData['price'] && sellData['amount'])
      sellData['total'] = parseFloat(sellData['price'] * sellData['amount'] || 0).toFixed(8);
    else sellData['total'] = '';
    this.setState({ sellData });
  }

  submitSellData = () => {
    let { sellData, selectedCoin } = this.state;

    sellData['loader'] = true;
    sellData['type'] = 'limit';
    sellData['action'] = 'sell';
    sellData['pair'] = selectedCoin;
    this.props.placeOrder(sellData);
  }

  percentChange = (type, percent) => {
    let { userBalance } = this.props;
    let { buyData, sellData, masterCoin, altCoin, coinStats } = this.state;
    let balance = type == 'buy' ? userBalance[masterCoin] : userBalance[altCoin];

    if (type === 'buy') {
      buyData['checked'] = percent;
      buyData['price'] = buyData['price'] || coinStats['price'];
      buyData['total'] = parseFloat(balance * (percent / 100)).toFixed(8);
      buyData['amount'] = parseFloat(buyData['total'] / buyData['price']).toFixed(8);
    } else if (type === 'sell') {
      sellData['checked'] = percent;
      sellData['price'] = sellData['price'] || coinStats['price'];
      sellData['amount'] = parseFloat(balance * (percent / 100)).toFixed(8);
      sellData['total'] = parseFloat(sellData['price'] * sellData['amount']).toFixed(8);
    }

    this.setState({ buyData, sellData });
  }

  render() {
    let { userBalance, coinStats } = this.props;
    let { masterCoin, altCoin, buyData, sellData } = this.state;
    return (
      <>
        <div className="bottom-buy-form mb-5">
          <div className="inner-form-block">
            <ul className="limint-btn">
              {/* <li><a href="#">limit</a></li> */}
              {/* <li><a href="#">market</a></li> */}
              {/* <li><select className="mdb-select md-form">
                <option value="1">stop-limit</option>
                <option value="2">stop-limit</option>
                <option value="3">stop-limit</option>
              </select>
              </li> */}
            </ul>
            <div className="form-sell-buy">
              <ul className="rate-area">
                <li>buy {altCoin}</li>
                <li>
                  <img src={require("../../static/images/icons/9.png")} alt="Softtik Exchange" />
                  <NumberFormat value={userBalance[masterCoin] || 0} />  {masterCoin}
                </li>
              </ul>
              <div className="form-area">
                <form action="" method="" type="submit">

                  <label>Price:<span>{masterCoin}</span></label>
                  <input type="number" name="price" placeholder="" autocomplete="off" value={buyData['price']} onChange={this.handleBuyChange} />

                  <label>Amount:<span>{altCoin}</span></label>
                  <input type="number" name="amount" placeholder="" autocomplete="off" value={buyData['amount']} onChange={this.handleBuyChange} />

                  <label>Total:<span>{masterCoin}</span></label>
                  <input type="number" name="total" placeholder="" autocomplete="off" value={buyData['total']} disabled />

                  <div className="input-check-box">
                    <div className="form-check-inline">
                      <label className="form-check-label">
                        <input type="radio" className="form-check-input" value="0" checked={buyData['checked'] == '0'} name="optradio" onClick={() => this.percentChange('buy', '0')} />
                        0%
                        <div className="check" />
                      </label>
                    </div>
                    <div className="form-check-inline">
                      <label className="form-check-label">
                        <input type="radio" className="form-check-input" value='25' checked={buyData['checked'] == '25'} name="optradio" onClick={() => this.percentChange('buy', '25')} />
                        25%
                        <div className="check" />
                      </label>
                    </div>
                    <div className="form-check-inline ">
                      <label className="form-check-label">
                        <input type="radio" className="form-check-input" value='50' checked={buyData['checked'] == '50'} name="optradio" onClick={() => this.percentChange('buy', '50')} />
                        50%
                        <div className="check" />
                      </label>
                    </div>
                    <div className="form-check-inline">
                      <label className="form-check-label">
                        <input type="radio" className="form-check-input" value='75' checked={buyData['checked'] == '75'} name="optradio" onClick={() => this.percentChange('buy', '75')} />
                        75%
                        <div className="check" />
                      </label>
                    </div>
                    <div className="form-check-inline last">
                      <label className="form-check-label">
                        <input type="radio" className="form-check-input" value="100" checked={buyData['checked'] == '100'} name="optradio" onClick={() => this.percentChange('buy', '100')} />
                        100%
                        <div className="check" />
                      </label>
                    </div>
                  </div>
                  <Button
                    type="button"
                    disabled={buyData['loader']}
                    onClick={this.submitBuyData}
                  >
                    {!buyData['loader'] ? `Buy ${altCoin}` : <i className="fa fa-spinner fa-spin fa-1x fa-fw" />}
                  </Button>
                </form>
              </div>
            </div>
          </div>
          <div className="inner-form-block style-two">
            <div className="form-sell-buy">
              <ul className="rate-area">
                <li>sell {altCoin}</li>
                <li>
                  <img src={require("../../static/images/icons/9.png")} alt="Softtik Exchange" />
                  <NumberFormat value={userBalance[altCoin] || 0} />  {altCoin}
                </li>
              </ul>
              <div className="form-area">
                <form action="" method="">
                  <label>Price:<span>{masterCoin}</span></label>
                  <input type="number" name="price" placeholder="" autocomplete="off" value={sellData['price']} onChange={this.handleSellChange} />

                  <label>Amount:<span>{altCoin}</span></label>
                  <input type="number" name="amount" placeholder="" autocomplete="off" value={sellData['amount']} onChange={this.handleSellChange} />

                  <label>Total:<span>{masterCoin}</span></label>
                  <input type="number" name="total" placeholder="" autocomplete="off" value={sellData['total']} disabled />

                  <div className="input-check-box">
                    <div className="form-check-inline">
                      <label className="form-check-label">
                        <input type="radio" className="form-check-input" value="0" checked={sellData['checked'] == '0'} name="optradio" onClick={() => this.percentChange('sell', '0')} />
                        0%
                        <div className="check style-two" />
                      </label>
                    </div>
                    <div className="form-check-inline">
                      <label className="form-check-label">
                        <input type="radio" className="form-check-input" value="25" checked={sellData['checked'] == '25'} name="optradio" onClick={() => this.percentChange('sell', '25')} />
                        25%
                        <div className="check style-two" />
                      </label>
                    </div>
                    <div className="form-check-inline ">
                      <label className="form-check-label">
                        <input type="radio" className="form-check-input" value="50" checked={sellData['checked'] == '50'} name="optradio" onClick={() => this.percentChange('sell', '50')} />
                        50%
                        <div className="check style-two" />
                      </label>
                    </div>
                    <div className="form-check-inline">
                      <label className="form-check-label">
                        <input type="radio" className="form-check-input" value="75" checked={sellData['checked'] == '75'} name="optradio" onClick={() => this.percentChange('sell', '75')} />
                        75%
                        <div className="check style-two" />
                      </label>
                    </div>
                    <div className="form-check-inline last">
                      <label className="form-check-label">
                        <input type="radio" className="form-check-input" value="100" checked={sellData['checked'] == '100'} name="optradio" onClick={() => this.percentChange('sell', '100')} />
                        100%
                        <div className="check style-two" />
                      </label>
                    </div>
                  </div>
                  <Button
                    type="button"
                    disabled={sellData['loader']}
                    onClick={this.submitSellData}
                  >
                    {!sellData['loader'] ? `Sell ${altCoin}` : <i className="fa fa-spinner fa-spin fa-1x fa-fw" />}
                  </Button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}

const mapDispatchToProps = { placeOrder };

const mapStateToProps = ({ Trading }) => {
  let { selectedCoin, userBalance, coinStats } = Trading;
  return { selectedCoin, userBalance, coinStats };
};

export default connect(mapStateToProps, mapDispatchToProps)(BuySell);