import axios from "axios";
import jwt_decode from 'jwt-decode';
import EventBus from "eventing-bus";
import { socket } from '../config';
import { put, all, takeLeading, call } from "redux-saga/effects";
import { togglePaypalCheckout, } from '../actions/TEX';

let id = '';
let token = localStorage.getItem('token');
if (token) id = (jwt_decode(token))['_id'];

export const updateIDTEX = (newId) => id = newId;

function* paypalCheckout({ payload }) {
    const { error, response } = yield call(postCall, { path: `/buySilver`, body: payload });
    if (error) EventBus.publish("error", error["response"]["data"]["message"]);
    else if (response) EventBus.publish("success", response['data']['message']);
    yield put(togglePaypalCheckout(true));
};


function* buyTEXSockets() {

    //************** Deposit History  **************/
    socket.emit('silver:orders', id);

    /************** TEX Graph  **************/
    socket.emit('silver:graph');

    /************** TEX Price  **************/
    socket.emit('silver:price');
}

function* actionWatcher() {
    yield takeLeading("PAYPAL_CHECKOUT", paypalCheckout);
    yield takeLeading('BUY_TEX_SOCKETS', buyTEXSockets);
}


function postCall({ path, body }) {
    return axios
        .post(path, body)
        .then(response => ({ response }))
        .catch(error => {
            if (error.response.status === 401) EventBus.publish("tokenExpired");
            return { error };
        });
}

function putCall({ body, path }) {
    return axios
        .put(path, body)
        .then(response => ({ response }))
        .catch(error => {
            if (error.response.status === 401) EventBus.publish("tokenExpired");
            return { error };
        });
}

function getCall(path) {
    return axios
        .get(path)
        .then(response => ({ response }))
        .catch(error => {
            if (error.response.status === 401) EventBus.publish("tokenExpired");
            return { error };
        });
}

function deleteCall(path) {
    return axios
        .delete(path)
        .then(response => ({ response }))
        .catch(error => {
            if (error.response.status === 401) EventBus.publish("tokenExpired");
            return { error };
        });
}

export default function* rootSaga() {
    yield all([actionWatcher()]);
}
