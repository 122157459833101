// Withdraw
import classnames from 'classnames';
import { connect } from 'react-redux';
import React, { Component, Fragment } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';

import Crypto from './Crypto.js';
import Fiat from './Fiat.js';

class Deposit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: '1',
    };
  }

  toggle = tab => {
    const { activeTab } = this.state
    if (activeTab !== tab) this.setState({ activeTab: tab });
  }

  render() {
    let { activeTab } = this.state;
    return (
      <>
        <div class="deposit-inner">
          <div class="row clearfix">
            <div class="deposit-tabs mt-3 col-lg-12 col-md-12">
              {/* <Nav className="row" tabs>
                <NavItem className='offset-5 col-2 nav-items'>
                  <NavLink
                    className={classnames({ active: activeTab === '1' })}
                    onClick={() => { this.toggle('1'); }}>

                  </NavLink>
                </NavItem>
                <NavItem className='col-2 nav-items'>
                  <NavLink
                    className={classnames({ active: activeTab === '2' })}
                    onClick={() => { this.toggle('2'); }}>
                    <p>Fiat</p>
                  </NavLink>
                </NavItem>
              </Nav>

              <TabContent activeTab={activeTab}>
                <TabPane tabId="1"> */}
              <Crypto />
              {/* </TabPane>
                <TabPane tabId="2">
                  <Fiat />
                </TabPane>
              </TabContent> */}
            </div>
          </div>
        </div>
      </>
    )
  }
}

const mapDispatchToProps = {};

const mapStateToProps = ({ }) => {
  // let { } = ;
  // return { };
};

export default connect(mapStateToProps, mapDispatchToProps)(Deposit);