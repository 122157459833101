import Web3 from "web3";
import { Magic } from "magic-sdk";

let magic
if (process["env"]["REACT_APP_NETWORK"] === "mainnet")
  magic = new Magic(`${process["env"]["REACT_APP_MAGIC_KEY"]}`)
else
  magic = new Magic(`${process["env"]["REACT_APP_MAGIC_KEY"]}`, { network: "rinkeby" })

let web3 = new Web3(magic["rpcProvider"])

export { web3, magic }