import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';

import './index.css';
import Balance from './Balance';
import Convert from './Convert';
import Deposit from './Deposit/index';
import Withdraw from './Withdraw/index';
import Navbar from '../../components/Navbar';
import NumberFormat from '../../components/NumberFormat';
import { getWalletBalance } from '../../store/actions/Wallet';

class Wallet extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    props.getWalletBalance();
  }

  render() {
    let { lightMode, overview } = this.props;
    let { pathname } = this.props.location;

    return (
      <>
        <div className={`${lightMode ? 'light' : 'dark'}`}>
          <Navbar {...this.props} />
          <section class='deposit-table'>
            <div class='container'>
              <div class='row'>
                <div class='col-12'>
                  <div class='table-responsive text-center'>
                    <table class='table'>
                      <thead>
                        <tr>
                          <th scope='col' className='first'>Estimated Value </th>
                          <th scope='col'>Available</th>
                          <th scope='col' className='last'> In Order</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td class=''><NumberFormat value={overview['btcTotal'] || 0} coin={'BTC'} /> BTC</td>
                          <td class=''><NumberFormat value={overview['availableBtc'] || 0} coin={'BTC'} /> BTC</td>
                          <td class=''><NumberFormat value={overview['inOrderBtc'] || 0} coin={'BTC'} /> BTC</td>
                        </tr>
                        <tr>
                          <td><NumberFormat value={overview['usdtTotal'] || 0} coin={'USDT'} /> $</td>
                          <td><NumberFormat value={overview['availableUsdt'] || 0} coin={'USDT'} /> $</td>
                          <td><NumberFormat value={overview['inOrderUsdt'] || 0} coin={'USDT'} /> $</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section class='deposit-area balnce-table-sec'>
            <div class='container'>
              <div class='row'>
                <div class='col-12'>
                  <ul class='nav-deposit'>
                    <li class={`${(pathname === '/wallet/' || pathname === '/wallet') && 'active'}`}>
                      <Link to='/wallet'>Balance</Link>
                    </li>
                    <li class={`${pathname.match('deposit') && 'active'}`}>
                      <Link to='/wallet/deposit'>Deposit</Link>
                    </li>
                    <li class={`${pathname.match('withdraw') && 'active'}`}>
                      <Link to='/wallet/withdraw'>Withdraw</Link>
                    </li>
                    {/* <li class={`${pathname.match('convert') && 'active'}`}>
                      <Link to='/wallet/convert'>Convert</Link>
                    </li> */}
                  </ul>
                  <Switch>
                    <Route exact path='/wallet' component={Balance} />
                    <Route path='/wallet/deposit' component={Deposit} />
                    <Route path='/wallet/withdraw' component={Withdraw} />
                    {/* <Route path='/wallet/convert' component={Convert} /> */}
                    {/* <Route path='/wallet/history' component={History} /> */}
                  </Switch>
                </div>
              </div>
            </div>
          </section>
        </div>
      </>
    )
  }
}

const mapDispatchToProps = { getWalletBalance };

const mapStateToProps = ({ Auth, Wallet }) => {
  let { lightMode } = Auth;
  let { overview } = Wallet;
  return { lightMode, overview };
};

export default connect(mapStateToProps, mapDispatchToProps)(Wallet);