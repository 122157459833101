import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import React, { Component } from 'react';

import './index.css';
import Navbar from '../../components/Navbar';
import {   myPage } from '../../store/actions/Auth';


class VerifyKYC extends Component {

    constructor(props) {
        super(props);
        this.state = {
        };
        props.myPage();
        window.scrollTo(0, 0);
    };

    componentWillReceiveProps({ userDetails, kycID, history }) {
        if (userDetails['_id'] == kycID) setTimeout(() => history.push('/myPage'), 3000);
    }

    render() {
        let { lightMode, userDetails } = this.props;

        return (
            <div className={`page-wrapper-inner ${lightMode ? 'light' : 'dark'}`}>
                <Navbar {...this.props} />

                <section className="sms-anthtication verify-kyc">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-10 offset-lg-1 col-md-12 col-sm-12">
                                <div className="sms-anthtication-form">
                                    <div className="dash-top-title">
                                        <div className='row'>
                                            <div className='col-1 back-btn'>
                                                <Link to='/myPage'><i class="fa fa-arrow-left text-white mt-2" aria-hidden="true"></i></Link>
                                            </div>
                                            <div className='col-11 title d-flex justify-content-center'>
                                                <h2>Verify KYC</h2>
                                            </div>
                                        </div>
                                    </div>

                                    <form className="form-area">
                                        <div className="row">
                                            <div className='col-12 kyc-frame d-flex justify-content-center'>
                                                <iframe src={`https://CrowdPoint.intelitruth.com/?PROFILE_SESSION_ID=${(new Date()).getTime() + '_' + userDetails['_id']}&PROFILE_UUID=${userDetails['_id']}`} style={{ width: '800px', height: '100vh' }} allow="geolocation; microphone; camera; midi; encrypted-media;" sandbox="allow-forms allow-pointer-lock allow-popups allow-same-origin allow-scripts allow-top-navigation allow-modals"></iframe>
                                            </div>
                                        </div>
                                    </form>
                                </div>

                            </div>
                        </div>
                    </div>
                </section>

            </div>
        );
    }
}

const mapDispatchToProps = { myPage };
const mapStateToProps = ({ Auth }) => {
    let { lightMode, userDetails, kycID } = Auth;
    return { lightMode, userDetails, kycID };
}
export default connect(mapStateToProps, mapDispatchToProps)(VerifyKYC);