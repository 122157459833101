import { connect } from 'react-redux';
import React, { Component } from 'react';

import "./index.css";
import { createAPIKey } from '../../store/actions/Auth';

class NewKey extends Component {

  constructor(props) {
    super(props);
    this.state = {
      key: {
        label: '',
      }
    };
  }

  handleChange = (e) => {
    const { key } = this.state;
    key[e.target.name] = e.target.value;
    this.setState({ key });
  }

  handleAPIKey = () => this.props.createAPIKey({ data: this.state.key });

  render() {
    let { lightMode } = this.props;
    return (
      <>
        <section className="api-create">
          <div className="container">
            <div className="row">
              <div className="offset-lg-2 col-lg-8 col-md-12">
                <div className="api-sec">
                  <h2>Create API</h2>
                  <div className="api-box">
                    <p>Creating an API private key provides access to markets and teal-time trading services on Softtik via a third-party site or application.</p>
                    <form className="api-key-form">
                      <input onChange={this.handleChange} type="text" name="label" placeholder="Give the API key a label" />
                      <button type="button" onClick={this.handleAPIKey}>Give the API key a label</button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    )
  }
}

const mapDispatchToProps = { createAPIKey };
const mapStateToProps = ({ Auth }) => {
  let { lightMode } = Auth;
  return { lightMode };
};
export default connect(mapStateToProps, mapDispatchToProps)(NewKey);
