import { connect } from 'react-redux';
import React, { Component } from 'react';

import NumberFormat from '../../components/NumberFormat';

class Orders extends Component {
  render() {
    let { buyOrders, sellOrders, tradeHistory, coinStats, selectedCoin } = this.props;
    return (
      <>
        {/**************  Buy && Sell Orders of Pair  **************/}

        <div className="group-price-bock">
          <div className="table-area">
            <table className="table table-responsive-sm table-dark">
              <div className="table-head-sec">
                <thead>
                  <tr>
                    <th scope="col">Price ({selectedCoin.split('/')[1]})</th>
                    <th scope="col">Amount ({selectedCoin.split('/')[0]})</th>
                    <th scope="col">Total ({selectedCoin.split('/')[1]})</th>
                  </tr>
                </thead>
              </div>

              <tbody>
                {/**************  Sell Orders of Pair  **************/}
                <div className="table-top-sec">
                  <tbody>
                    {sellOrders.map(data => {
                      return (
                        <tr>
                          <td className="red">
                            <NumberFormat value={data['unitPrice']} coin={data['pair'].split('/')[1]} />
                          </td>
                          <td>
                            <NumberFormat value={data['amount'] - (data['filledAmount'] || 0)} coin={data['pair'].split('/')[1]} />
                          </td>
                          <td>
                            <NumberFormat value={(data['amount'] - (data['filledAmount'] || 0)) * data['unitPrice']} coin={data['pair'].split('/')[1]} />
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </div>

                <div className="table-middle-sec">
                  <tbody>
                    {coinStats['symbol'] &&
                      <tr className="active">
                        <th scope="row" className={coinStats['change'] >= 0 ? 'green' : 'red'}>
                          <NumberFormat value={coinStats['price']} coin={coinStats['symbol'].split('/')[1]} />
                          <i className={coinStats['change'] >= 0 ? 'fa fa-long-arrow-alt-up' : 'fa fa-long-arrow-alt-down'}></i>
                        </th>
                        <th> <NumberFormat value={coinStats['price']} coin={coinStats['symbol'].split('/')[1]} /> </th>
                        <th className={coinStats['change'] >= 0 ? 'green' : 'red'}>More <i className="fa fa-signal"></i></th>
                      </tr>
                    }
                  </tbody>
                </div>

                {/**************  Buy Orders of Pair  **************/}
                <div className="table-bottom-sec">
                  <tbody>
                    {buyOrders.map(data => {
                      return (
                        <tr>
                          <td className="green">
                            <NumberFormat value={data['unitPrice']} coin={data['pair'].split('/')[1]} />
                          </td>
                          <td>
                            <NumberFormat value={data['amount'] - (data['filledAmount'] || 0)} coin={data['pair'].split('/')[1]} />
                          </td>
                          <td>
                            <NumberFormat value={(data['amount'] - (data['filledAmount'] || 0)) * data['unitPrice']} coin={data['pair'].split('/')[1]} />
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </div>

              </tbody>
            </table>
          </div>
        </div>

        {/**************  Trading History of Pair  **************/}

        <div className="group-price-bock style-two">
          <div className="table-area">
            <table className="table table-striped table-responsive-sm table-dark">
              <tbody>
                {tradeHistory.map(data => {
                  return (
                    <tr>
                      <td className={`${data['type'] == 'buy' ? 'green' : 'red'}`}>
                        <NumberFormat value={data['price']} coin={data['pair'].split('/')[1]} />
                      </td>
                      <td>
                        <NumberFormat value={data['amount']} coin={data['pair'].split('/')[1]} />
                      </td>
                      <td>
                        <NumberFormat value={data['price'] * data['amount']} coin={data['pair'].split('/')[1]} />
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      </>
    )
  }
}

const mapDispatchToProps = {};

const mapStateToProps = ({ Trading }) => {
  let { buyOrders, sellOrders, tradeHistory, coinStats, selectedCoin } = Trading;
  return { buyOrders, sellOrders, tradeHistory, coinStats, selectedCoin };
};

export default connect(mapStateToProps, mapDispatchToProps)(Orders);