import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import MuiPhoneInput from 'material-ui-phone-number';

import './index.css';
import Navbar from '../../components/Navbar';

import { sendSMS, smsKey, myPage } from '../../store/actions/Auth';


class SmsAuthentication extends Component {

    constructor(props) {
        super(props);
        this.state = {
            phone: props.userDetails['phone'],
            smsKey: '',
        };
        props.myPage();
    };

    componentWillReceiveProps({ userDetails }) {
        if (userDetails['phone']) this.setState({ phone: userDetails['phone'] })
    }

    handleSendSMS = () => this.props.sendSMS({ phone: this.state.phone });

    handleSMSKey = () => this.props.smsKey({ smsKey: this.state.smsKey, history: this.props.history });

    render() {
        let { lightMode, } = this.props;
        let { phone, smsKey } = this.state;

        return (
            <div className={`page-wrapper-inner ${lightMode ? 'light' : 'dark'}`}>
                <Navbar {...this.props} />

                <section className="sms-anthtication">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-10 offset-lg-1 col-md-12 col-sm-12">
                                <div className="sms-anthtication-form">
                                    <div className="dash-top-title">
                                        <div className='row'>
                                            <div className='col-1 back-btn'>
                                                <Link to='/myPage'><i class="fa fa-arrow-left text-white mt-2" aria-hidden="true"></i></Link>
                                            </div>
                                            <div className='col-md-11 col-sm-10 title text-center d-flex justify-content-center'>
                                                <h2>Enable SMS Authentication</h2>
                                            </div>
                                        </div>
                                    </div>

                                    <form className="form-area">
                                        <div className="row">
                                            <div className="form-group col-lg-12 col-md-12 col-sm-12">
                                                <div className="form-item">
                                                    <MuiPhoneInput
                                                        fullWidth
                                                        name="phone"
                                                        value={phone}
                                                        margin="normal"
                                                        variant="outlined"
                                                        autoFormat={false}
                                                        defaultCountry='us'
                                                        id='standard-full-width'
                                                        className='sms-input-field'
                                                        onChange={(phone) => this.setState({ phone })}
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-group col-lg-8 col-md-12 col-sm-12">
                                                <TextField
                                                    fullWidth
                                                    name="smsKey"
                                                    type='number'
                                                    value={smsKey}
                                                    variant="outlined"
                                                    id='standard-full-width'
                                                    className='sms-input-field'
                                                    placeholder="Phone Verification Code"
                                                    onChange={(e) => this.setState({ smsKey: e.target.value })}
                                                    helperText='Please click Send SMS button to get verification code'
                                                />
                                            </div>
                                            <div className="form-group send-sms col-lg-4 col-md-12 col-sm-12">
                                                <button type="button" className="theme-btn btn-style-four" onClick={this.handleSendSMS}>Send SMS</button>
                                            </div>
                                        </div>

                                    </form>
                                </div>
                                <div className="bottom-btn text-center">
                                    <button type='button' className="theme-btn btn-style-four submit-auth-btn" onClick={this.handleSMSKey}>Enable SMS Auth</button>
                                </div>
                                <div className="notice-bottom">
                                    {/* <p><span><img src="images/icons/19.png" alt="" /></span>   Do not disclose Password, Google Authentication and SMS to anyone, including STABLE support.</p> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        );
    }
}

const mapDispatchToProps = { sendSMS, smsKey, myPage };
const mapStateToProps = ({ Auth, Trading }) => {
    let { lightMode, userDetails } = Auth;
    let { } = Trading;
    return { lightMode, userDetails };
}
export default connect(mapStateToProps, mapDispatchToProps)(SmsAuthentication);