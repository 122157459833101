import { connect } from "react-redux";
import React, { Component } from "react";
import { Redirect } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Navbar from "../../components/Navbar";
import { myPage, magicValues, OnboardValues, AuthorizationValues, CreateCertificate } from "../../store/actions/Auth";
import Wallet from './components/wallet';
import Authorize from './components/authorize';
import Certificate from './components/createCertificate';
import UseronBoard from './components/onBoard';
import UserCertifiate from './components/userDetails';

import './index.css';


class MyPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeStep: 0,
        }
        props.myPage();
    };


    componentDidMount() {
        let { userDetails } = this.props;
        if (userDetails['step'] === 'default') { this.setState({ activeStep: 0 }) }
        else if (userDetails['step'] === 'wallet') { this.setState({ activeStep: 1 }) }
        else if (userDetails['step'] === 'onborad') { this.setState({ activeStep: 2 }) }
        else if (userDetails['step'] === 'authorized') { this.setState({ activeStep: 3 }) }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.userDetails['step'] !== this.props.userDetails['step']) {
            if (this.props.userDetails['step'] === 'default') {
                this.setState({ activeStep: 0 })
            }else if (this.props.userDetails['step'] === 'wallet') {
                // this.setState({ activeStep: 1 })
            }else if (this.props.userDetails['step'] === 'onborad') {
                this.setState({ activeStep: 2 })
            }else if (this.props.userDetails['step'] === 'authorized') {
                this.setState({ activeStep: 3 })
            }
        }
    }

    handleNext = () => {
        const { activeStep } = this.state
        this.setState({ activeStep: activeStep + 1 })
    };

    getStepContent = (stepIndex) => {
        switch (stepIndex) {
            case 0:
                return (
                    <Wallet handleNext={this.handleNext} />
                )
            case 1:
                return (
                    <UseronBoard handleNext={this.handleNext} />
                )
            case 2:
                return (
                    <Authorize handleNext={this.handleNext} />
                )
            case 3:
                return (
                    <Certificate handleNext={this.handleNext} />
                )
        }
    }

    render() {
        let { lightMode, userDetails } = this.props;
        let { activeStep } = this.state;

        if (userDetails['kyc'] && userDetails['kyc']['reviewStatus'] == "INCOMPLETE") {
            return <Redirect to="/myPage" />
        }
        return (
            <>
                <div className={`magic-wallet page-wrapper-inner ${lightMode ? "light" : "dark"}`}>
                    <Navbar {...this.props} />
                    <section className="step-sec">
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <div className="step-area">
                                        <div className="step-count">
                                            {userDetails['step'] !== "certificate" &&
                                                <ul className="count-area">
                                                    <li className={activeStep == 0 ? "active" : null}><span className="step">STEP<i className="icon"><img src={require("../../static/images/steps/icon.png")} alt="" /></i></span><span className="number">1</span></li>
                                                    <li className={activeStep == 1 ? "active" : null}><span className="step step-one">STEP<i className="icon"><img src={require("../../static/images/steps/icon.png")} alt="" /></i></span><span className="number">2</span></li>
                                                    <li className={activeStep == 2 ? "active" : null}><span className="step step-two">STEP<i className="icon"><img src={require("../../static/images/steps/icon.png")} alt="" /></i></span><span className="number">3</span></li>
                                                    <li className={activeStep == 3 ? "active" : null}><span className="step step-three">STEP<i className="icon"><img src={require("../../static/images/steps/icon.png")} alt="" /></i></span><span className="number">4</span></li>
                                                </ul>
                                            }
                                        </div>
                                        <div>
                                            {userDetails['step'] == "certificate" ?
                                                <UserCertifiate /> :
                                                <Typography>{this.getStepContent(activeStep)}</Typography>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </>
        );
    }
}

const mapDispatchToProps = {
    myPage, magicValues, OnboardValues, AuthorizationValues, CreateCertificate
};

const mapStateToProps = ({ Auth }) => {
    let { userDetails, onBoard, authorization, createCertificate, magicReturn } = Auth;
    return { userDetails, onBoard, authorization, createCertificate, magicReturn };
};

export default connect(mapStateToProps, mapDispatchToProps)(MyPage);