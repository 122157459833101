// CONVERT
import { connect } from 'react-redux';
import React, { Component, Fragment } from 'react';

import { getCoinWallet, setCoin } from '../../../store/actions/Wallet';

class Convert extends Component {
  constructor(props) {
    super(props);
    this.state = {
      convertFrom: '',
      convertTo: '',
      amount: '',
      walletList: [],
      selectedWallet: props.selectedWallet,
    };
    props.getCoinWallet(props.selectedWallet);
  };

  componentWillReceiveProps({ selectedWallet, wallets }) {
    if (selectedWallet !== this.state.selectedWallet) {
      this.setState({ selectedWallet });
      this.props.getCoinWallet(selectedWallet);
    }
    this.setState({ walletList: wallets.filter(({ symbol }) => symbol !== selectedWallet) });
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };


  render() {
    let { convertFrom, convertTo, amount, walletList } = this.state;
    let { coinWallet } = this.props;
    let coinData = coinWallet['coinId'] || {};
    return (
      <div class="deposit-inner">
        <div class="row clearfix">
          <div class="deposit-tabs mt-3 col-lg-12 col-md-12">

            <div className="row clearfix">
              <div className="col-lg-6 col-md-12 col-sm-12">
                <div className="qr-sec">
                  <h5>Internal Transfers</h5>

                  <div className="accordion md-accordion" id="accordionEx" role="tablist" aria-multiselectable="true">
                    <div className="card">

                      <div className="card-body withdraw-card">
                        <form className="withdraw-balance convert-tab">
                          <div className="group-form">
                            <label>From</label>
                            <input name='convertFrom' value={convertFrom} type="text" className="control-form bg-input" placeholder="Lipses Wallet" onChange={this.handleChange} />
                          </div>
                          <div className="group-form">
                            <div className="to-area">
                              <p>TO</p>
                              <i className="icon fa fa-arrow-down"></i>
                            </div>
                          </div>
                          <div className="group-form">
                          </div>
                          <div className="group-form">
                            <div className="card-header" role="tab" id="headingOne1">
                              <div class="btn-group">
                                <button type="button" class="btn btn-secondary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                  <span class="icon-left">
                                    <img src={coinData['icon']} className="dropdown-icon" alt="Bitcoin Logo" />
                                  </span>
                                  {coinData['coinName']} ({coinData['symbol']})
                                </button>
                                <div class="dropdown-menu">
                                  {walletList.map(wallet => (
                                    <button class="dropdown-item" type="button" onClick={() => this.props.setCoin(wallet['symbol'])}>
                                      <span class="icon-left">
                                        <img src={wallet['icon']} className='dropdown-icon' alt="Bitcoin Logo" />
                                      </span>
                                      {wallet['name']} ({wallet['symbol']})
                                    </button>
                                  ))}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="group-form style-2">
                            <input name='amount' value={amount} type="number" className="control-form control-form-amount" placeholder="$2500" onChange={this.handleChange} />
                            <span className="max-button" >Max</span>
                          </div>
                          <div className="group-form">
                            <button type='button' className="theme-btn btn-style-three">Submit</button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>

                </div>
              </div>

              {/* History Table */}
              <div className="col-lg-6 col-md-12 col-sm-12">
                <div className="history-deposit">
                  <h5>History</h5>
                  <div className="table-responsive-md">
                    <table className="table table-clr text-center">
                      <thead>
                        <tr>
                          <th scope="col">Time <span className="icn-box"><i className="fa fa-caret-up"></i><i className="fa fa-caret-down"></i></span></th>
                          <th scope="col">Coin <span className="icn-box"><i className="fa fa-caret-up"></i><i className="fa fa-caret-down"></i></span></th>
                          <th scope="col">Amount <span className="icn-box"><i className="fa fa-caret-up"></i><i className="fa fa-caret-down"></i></span></th>
                          <th scope="col">Status <span className="icn-box"><i className="fa fa-caret-up"></i><i className="fa fa-caret-down"></i></span></th>
                          <th scope="col">Tx ID <span className="icn-box"><i className="fa fa-caret-up"></i><i className="fa fa-caret-down"></i></span></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>-</td>
                          <td>-</td>
                          <td>-</td>
                          <td>-</td>
                          <td>-</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    )
  }
}

const mapDispatchToProps = { getCoinWallet, setCoin };

const mapStateToProps = ({ Wallet }) => {
  let { selectedWallet, coinWallet, wallets } = Wallet;
  return { selectedWallet, coinWallet, wallets };
};

export default connect(mapStateToProps, mapDispatchToProps)(Convert);