import { connect } from "react-redux";
import React, { Component } from "react";
import { } from "../../../store/actions/Auth";

class UserCertificate extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    };

    render() {
        const { userDetails } = this.props
        return (
            <>
                <div class="inner-box">
                    <div class="logo-area">
                        <img src={require("../../../static/images/TEX-Wallet.jpeg")} alt="" />
                    </div>
                    <div class="content-box">
                        <div class="congrets-area">
                            <img src={require("../../../static/images/steps/10.png")} alt="" />
                            <h2>Congratulations</h2>
                            <h3>Certificate of <br /> Authentication</h3>
                            <p>Aenean id porttitor purus, et cursus augue. Vestibulum nibh justo, molestie in dictum in, posuere non tellus. Etiam dolor purus, porta eut</p>
                        </div>
                    </div>
                </div>

                {/* <section className="sms-anthtication">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-10 offset-lg-3 col-md-12 col-sm-12">
                                <div className="sms-anthtication-form">
                                    <div className="dash-top-title">
                                        <div className='row'>
                                            <div className='col-md-12 col-sm-12 title text-center d-flex justify-content-center'>
                                                <h2 style={{ color: 'white' }}>User Details</h2>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="bottom-btn text-center">
                                        <div className="form-group col-lg-12 col-md-12 col-sm-12">
                                            <div className="form-item">
                                                Public Address: {userDetails['magicAddress']}
                                            </div>
                                        </div>
                                        <div className="form-group col-lg-12 col-md-12 col-sm-12">
                                                Email :{userDetails['email']}
                                        </div>
                                        <div className="form-group col-lg-12 col-md-12 col-sm-12">
                                                Verify Id :{userDetails['verkey']}
                                        </div>
                                        <div className="form-group col-lg-12 col-md-12 col-sm-12">
                                                DID :{userDetails['did']}
                                        </div>
                                        <div className="form-group col-lg-12 col-md-12 col-sm-12">
                                                Certificate Id :{userDetails['credId']}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}
            </>
        );
    }
}

const mapDispatchToProps = {
};

const mapStateToProps = ({ Auth }) => {
    let { userDetails } = Auth;
    return { userDetails };
};
export default connect(mapStateToProps, mapDispatchToProps)(UserCertificate);