import React, { Component, Fragment } from 'react';
import { Button } from 'reactstrap';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import VpnKey from '@material-ui/icons/VpnKey';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import Visibility from '@material-ui/icons/Visibility';
import AccountCircle from '@material-ui/icons/AccountCircle';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';

import MainNavbar from '../../components/MainNavbar';
import { login, load, verifyEmail, toggleAuthModal, toggleResendEmail, resendEmail, loginSms, verifySmsLogin, loginVerifyGoogle } from '../../store/actions/Auth';

import './index.css';

class Login extends Component {
  constructor() {
    super();
    this.state = {
      formData: {
        email: '',
        password: ''
      },
      showPassword: false,
      enabled: '',
      selected: '',
      smsKey: '',
      code: { first: '', second: '', third: '', fourth: '', fifth: '', sixth: '' }
    };
  }

  componentDidMount() {
    let { location } = this.props;
    if (location.pathname.match('verify_email')) {
      let data = location.pathname.split('/')[--location.pathname.split('/').length];
      this.props.verifyEmail({ data, history: this.props.history })
    }
  }

  submitLogin = () => {
    this.props.load(true);
    this.props.login({ data: this.state.formData, history: this.props.history });
  }

  componentWillReceiveProps({ userAuth }) {
    if (userAuth) {
      let selected = userAuth['enabled'] === 'sms' || userAuth['enabled'] === 'both' ? 'sms' : '2fa';
      this.setState({ enabled: userAuth['enabled'], selected });
    }
  }

  sendSms = () => {
    let { id } = this.props.userAuth;
    this.props.loginSms(id);
  }

  submitSms = () => {
    let { smsKey } = this.state;
    let { history } = this.props;
    let { id } = this.props.userAuth;

    let data = { smsKey };
    this.props.verifySmsLogin({ data, id, history });
  }

  handleFormChange = ({ target }) => {
    const { formData } = this.state;
    formData[target.name] = target.value;
    this.setState({ formData });
  }

  changeTab = (selected) => this.setState({ selected });

  handleMouseDownPassword = (e) => e.preventDefault();
  handleClickShowPassword = () => this.setState({ showPassword: !this.state.showPassword });
  keyPressed = (e) => {
    if (e.key === "Enter") this.submitLogin();
  }

  handleChangedCode = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    let { code } = this.state;
    code[name] = value;
    this.setState({ code });

    let input;
    if (name === 'first') input = document.getElementById('second');
    if (name === 'second') input = document.getElementById('third');
    if (name === 'third') input = document.getElementById('fourth');
    if (name === 'fourth') input = document.getElementById('fifth');
    if (name === 'fifth') input = document.getElementById('sixth');
    if (input) {
      input.focus();
      input.select();
    }
    if (name === 'sixth') {
      document.getElementById('sixth').blur();
      let { history } = this.props;
      let { id } = this.props.userAuth;
      let token_code = code.first + code.second + code.third + code.fourth + code.fifth + code.sixth;
      this.props.loginVerifyGoogle({ data: { token_code }, id, history });
      setTimeout(() => {
        if (document.getElementById('first')) document.getElementById('first').focus();
        this.setState({ code: { first: '', second: '', third: '', fourth: '', fifth: '', sixth: '' } })
      }, 1500)
    }
  }

  handleResendEmail = () => {
    let { email } = this.state.formData;
    this.props.resendEmail({ email })
  };

  render() {
    let { loading, isUserAuthModal, isResendEmail } = this.props;
    let { showPassword, enabled, selected, smsKey, code } = this.state;
    let { email, password } = this.state.formData;
    return (
      <>
        <MainNavbar {...this.props} />
        <section className="login-page">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="login-form">
                  <div className="row clearfix">
                    <div className="col-lg-6 offset-lg-3 col-md-12 col-sm-12">
                      <div className="form-area">
                        <div className="sec-title text-center">
                          <h2>Login</h2>
                        </div>
                        <ValidatorForm className="validatorForm pt-3" onSubmit={this.submitLogin}>
                          <Grid container spacing={1} className="group-input" alignItems="flex-end">
                            <Grid className="login-fields" item xs={12}>
                              <AccountCircle className='input-icon mt-4 mr-2' />
                              <div className="login-feild-full-width">
                                <CustomTextField
                                  fullWidth
                                  className="MyTextField"
                                  autoComplete='off'
                                  label="Email"
                                  name="email"
                                  type="text"
                                  value={email}
                                  margin="dense"
                                  onKeyPress={this.keyPressed}
                                  onChange={this.handleFormChange}
                                  validators={['required', 'isEmail']}
                                  errorMessages={['Please Enter Your Email', 'Email is not valid']}
                                />
                              </div>

                            </Grid>
                          </Grid>
                          <Grid container spacing={1} className="group-input" alignItems="flex-end">
                            <Grid className="login-fields" item xs={12}>
                              <VpnKey className='input-icon mt-4 mr-2' />
                              <div className="login-feild-full-width">
                                <CustomTextField
                                  fullWidth
                                  className="MyTextField"
                                  autoComplete='off'
                                  label="Password"
                                  name="password"
                                  value={password}
                                  margin="dense"
                                  onKeyPress={this.keyPressed}
                                  onChange={this.handleFormChange}
                                  type={showPassword ? 'text' : 'password'}
                                  validators={['required', 'isPassword']}
                                  errorMessages={['Password can not be empty']}
                                  inputProps={{
                                    autocomplete: 'new-password',
                                    form: { autoComplete: 'off' }
                                  }}
                                  InputProps={{
                                    endAdornment: <InputAdornment position="end">
                                      <IconButton
                                        style={{ color: '#a80382', marginBottom: '8px' }}
                                        aria-label="toggle password visibility"
                                        onClick={this.handleClickShowPassword}
                                        onMouseDown={this.handleMouseDownPassword}
                                      >
                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                      </IconButton>
                                    </InputAdornment>,
                                  }}
                                />
                              </div>

                            </Grid>
                          </Grid>
                          <div className="group-form submit-box">
                            <Button
                              type="Submit"
                              disabled={loading}
                              onClick={this.submitLogin}
                            >
                              {!loading ? 'Login' : <i className="fa fa-spinner fa-spin fa-1x fa-fw" />}
                            </Button>
                          </div>
                        </ValidatorForm>
                        <div className="signup-link row d-flex justify-content-center">
                          <p>Don't have an account? <Link to='/signup' className="signup-btn">Sign Up</Link></p>

                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* ---------------RESEND EMAIL MODAL--------------- */}
        <Modal isOpen={isResendEmail} toggle={this.props.toggleResendEmail} className='resend-email-modal'>
          <ModalHeader toggle={this.props.toggleResendEmail}>
            <a href='#'><img className='modal-logo' src={require('../../static/images/wallet-logo.png')} alt='modal-logo' /></a>
          </ModalHeader>
          <ModalBody>
            <div className='resend-email-body'>
              <p className='text-dark'>Please continue through your Email.</p>
            </div>
          </ModalBody>
          <ModalFooter className='mb-4'>
            <div>
              <button type="button" className="theme-btn btn-style-one user-auth-btn px-4" onClick={this.handleResendEmail}>
                Continue Registeration
              </button>
            </div>
          </ModalFooter>
        </Modal>

        {/* ---------------USER AUTHENTICATION MODAL--------------- */}
        <Modal isOpen={isUserAuthModal} toggle={this.props.toggleAuthModal} className='user-auth-modal'>
          <ModalHeader toggle={this.props.toggleAuthModal}>
            <a href='#'><img className='modal-logo' src={require('../../static/images/wallet-logo.png')} alt='modal-logo' /></a>
          </ModalHeader>
          <ModalBody>
            {enabled == 'both' ?
              <div className="row">
                <a className="col-5" onClick={() => this.changeTab('sms')}><p className={selected === 'sms' ? "selected-auth-title" : "auth-title"}>SMS AUTH</p></a>
                <div className="col-2 modal-divider"> <div className="modal-divider-line"></div> </div>
                <a className="col-5" onClick={() => this.changeTab('2fa')}><p className={selected === '2fa' ? "selected-auth-title" : "auth-title"}>GOOGLE AUTH</p></a>
              </div>
              : (enabled == '2fa') ?
                <div className="row">
                  <a className="col-12"><p className="auth-title user-single-auth-title">GOOGLE AUTHENTICATION</p></a>
                </div>
                : (enabled == 'sms') ?
                  <div className="row">
                    <a className="col-12"><p className="auth-title user-single-auth-title">SMS AUTHENTICATION</p></a>
                  </div>
                  : null
            }
            <hr />

            {selected === 'sms'
              ? <Fragment>
                <ValidatorForm className='sms-validator-form row my-4' onSubmit={this.changePassword}>
                  <div className="col-md-8 col-sm-12 auth-area">
                    <TextField
                      fullWidth
                      name="smsKey"
                      type='number'
                      value={smsKey}
                      variant="outlined"
                      id='standard-full-width'
                      className='sms-auth-input-field mt-4'
                      placeholder="SMS Authentication Code"
                      onChange={(e) => this.setState({ smsKey: e.target.value })}
                    />
                  </div>
                  <div className="col-md-4 col-sm-12 auth-area">
                    <button type="button" className="theme-btn btn-style-four sms-auth-btn mt-4" onClick={this.sendSms}>SEND SMS</button>
                  </div>
                </ValidatorForm>
              </Fragment>
              : null
            }

            {selected === '2fa'
              ? <Fragment>
                <ValidatorForm className="google-validator-form row my-5">
                  <div className="col-12 auth-area">
                    <TextField
                      id='first'
                      name='first'
                      type='number'
                      placeholder='-'
                      value={code.first}
                      className='google-input-field mx-2'
                      onChange={this.handleChangedCode}
                      validators={['required']}
                      errorMessages={['Code is Required']}
                    />
                    <TextField
                      id='second'
                      name='second'
                      type='number'
                      placeholder='-'
                      value={code.second}
                      className='google-input-field mx-2'
                      onChange={this.handleChangedCode}
                      validators={['required']}
                      errorMessages={['Code is Required']}
                    />
                    <TextField
                      id='third'
                      name='third'
                      type='number'
                      placeholder='-'
                      value={code.third}
                      className='google-input-field mx-2'
                      onChange={this.handleChangedCode}
                      validators={['required']}
                      errorMessages={['Code is Required']}
                    />
                    <TextField
                      id='fourth'
                      name='fourth'
                      type='number'
                      placeholder='-'
                      value={code.fourth}
                      className='google-input-field mx-2'
                      onChange={this.handleChangedCode}
                      validators={['required']}
                      errorMessages={['Code is Required']}
                    />
                    <TextField
                      id='fifth'
                      name='fifth'
                      type='number'
                      placeholder='-'
                      value={code.fifth}
                      className='google-input-field mx-2'
                      onChange={this.handleChangedCode}
                      validators={['required']}
                      errorMessages={['Code is Required']}
                    />
                    <TextField
                      id='sixth'
                      name='sixth'
                      type='number'
                      placeholder='-'
                      value={code.sixth}
                      className='google-input-field mx-2'
                      onChange={this.handleChangedCode}
                      validators={['required']}
                      errorMessages={['Code is Required']}
                    />
                  </div>
                </ValidatorForm>
              </Fragment>
              : null
            }
          </ModalBody>
          {selected === 'sms' &&
            <ModalFooter className='mb-4'>
              <div>
                <button type="button" className="theme-btn btn-style-one user-auth-btn px-2" onClick={this.submitSms}>
                  {!loading ? 'SUBMIT' : <i className="fa fa-spinner fa-spin fa-1x fa-fw" />}
                </button>
              </div>
            </ModalFooter>
          }
        </Modal>
      </>
    )
  }
}

const CustomTextField = withStyles({
  root: {
    '& .MuiInputBase-input': {
      color: '#000', // Text color
    },
    '& .MuiInput-underline:before': {
      borderBottomColor: '#000', // Semi-transparent underline
    },
    '& .MuiInput-underline:hover:before': {
      borderBottomColor: '#000', // Solid underline on hover
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#a80382', // Solid underline on focus
    },
  },
  input: {
    '&:-webkit-autofill': {
      transitionDelay: '9999s',
      transitionProperty: 'background-color, color',
    }
  }
})(TextValidator);

const mapDispatchToProps = { load, login, verifyEmail, toggleAuthModal, toggleResendEmail, resendEmail, loginSms, verifySmsLogin, loginVerifyGoogle };

const mapStateToProps = ({ Auth }) => {
  let { loading, userAuth, isUserAuthModal, isResendEmail } = Auth;
  return { loading, userAuth, isUserAuthModal, isResendEmail };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);