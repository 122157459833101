export const paypalCheckout = (data) => ({
  type: 'PAYPAL_CHECKOUT',
  payload: data
});

export const togglePaypalCheckout = (data) => ({
  type: 'TOGGLE_PAYPAL_CHECKOUT',
  payload: data
});

export const toggleTexHistory = () => ({
  type: 'TOGGLE_TEX_HISTORY',
});

export const setBuyTexHistory = (data) => ({
  type: 'SET_BUY_TEX_HISTORY',
  payload: data
});

export const setTexGraph = (data) => ({
  type: 'SET_TEX_GRAPH',
  payload: data
});

export const setTexPrice = (data) => ({
  type: 'SET_TEX_PRICE',
  payload: data
});

// /* BUY TEX SOCKET */ //

export const buyTEXSockets = () => ({
  type: 'BUY_TEX_SOCKETS',
});
