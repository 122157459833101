export const changeMode = (data) => ({
  type: 'CHANGE_MODE',
  payload: data
});

export const tradingSockets = () => ({
  type: 'TRADING_SOCKETS'
});

export const placeOrder = (data) => ({
  type: 'PLACE_ORDER',
  payload: data
});

export const cancelOrder = (data) => ({
  type: 'CANCEL_ORDER',
  payload: data
});

    // /* COINS ACTIONS */ //


export const changeCoin = (data) => ({
  type: 'CHANGE_COIN',
  payload: data
});

export const setAllCoins = (data) => ({
  type: 'SET_ALL_COINS',
  payload: data
});

export const coinStats = (data) => ({
  type: 'COIN_STATS',
  payload: data
});

export const tradeHistory = (data) => ({
  type: 'TRADE_HISTORY',
  payload: data
});

export const buyOrders = (data) => ({
  type: 'BUY_ORDERS',
  payload: data
});

export const sellOrders = (data) => ({
  type: 'SELL_ORDERS',
  payload: data
});

    // /* USERS ACTIONS */ //


export const openOrders = (data) => ({
  type: 'OPEN_ORDERS',
  payload: data
});

export const orderHistory = (data) => ({
  type: 'ORDER_HISTORY',
  payload: data
});

export const userBalance = (data) => ({
  type: 'USER_BALANCE',
  payload: data
});
