import axios from "axios";
import jwt_decode from 'jwt-decode';
import { socket } from '../config';
import EventBus from "eventing-bus";
import { put, all, takeLeading, call } from "redux-saga/effects";

function* placeOrder({ payload }) {
  const { error, response } = yield call(postCall, { path: `/spotTrading`, body: payload });
  if (error) {
    EventBus.publish("error", error["response"]["data"]["message"]);
    EventBus.publish('spot:order:placed', { status: 'error' });
  }
  else if (response) {
    EventBus.publish("success", response['data']['message']);
    EventBus.publish('spot:order:placed', { status: 'success' });
  }
}

function* cancelOrder({ payload }) {
  const { error, response } = yield call(putCall, { path: `/spotTrading/cancel/${payload}`, body: {} });
  if (error) EventBus.publish("error", error["response"]["data"]["message"]);
  else if (response) EventBus.publish("success", response['data']['message']);
}

function* tradingSockets() {
  let token = localStorage.getItem('token');
  let userId = (jwt_decode(token))['_id'];

  /* Get Open orders of User */
  socket.emit('spot:orders:open', userId);

  /* Get history of User */
  socket.emit('spot:order:history', userId);

  /* Get Balance of User */
  socket.emit('spot:balance', userId);
}

function* changeCoin({ payload }) {

  /* To get All Pairs Prices and Single Pair Price */
  socket.emit('coin_price', payload);

  /* Get Trading history of Pair */
  socket.emit('spot:trade:history', payload);

  /* Buy Orders of Pair */
  socket.emit('spot:orders:buy', payload);

  /* Sell Orders of Pair */
  socket.emit('spot:orders:sell', payload);
}


function* actionWatcher() {
  yield takeLeading("PLACE_ORDER", placeOrder);
  yield takeLeading("CANCEL_ORDER", cancelOrder);
  yield takeLeading("CHANGE_COIN", changeCoin);
  yield takeLeading("TRADING_SOCKETS", tradingSockets);
}

function postCall({ path, body }) {
  return axios
    .post(path, body)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
}

function putCall({ path, body }) {
  return axios
    .put(path, body)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
}

function getCall(path) {
  return axios
    .get(path)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
}

function deleteCall(path) {
  return axios
    .delete(path)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
}

export default function* rootSaga() {
  yield all([actionWatcher()]);
}
