import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { useAuth0 } from "@auth0/auth0-react";
import { HashLink } from 'react-router-hash-link';

import './index.css';
import { userUrl } from '../../store/config';
import { logoutUser } from '../../store/actions/Auth';

const MainNavbar = (props) => {

  const { loginWithRedirect, logout } = useAuth0();

  const logoutButton = () => {
    props.logoutUser();
    logout({ returnTo: userUrl });
  }

  let { auth, location } = props;
  let { pathname } = location;

  return (
    <>
      <header className="main-header">
        <div className="header-upper">
          <div className="inner-container">
            <div className="container-fluid clearfix">
              <div className="nav-inner">
                <div className="logo-outer">
                  <div className="logo">
                    <Link to='/'><img className="navbar-logo-img main-nav-logo" src={require("../../static/images/TEX-title.jpeg")} alt="Keep Exchange" /></Link>
                  </div>
                </div>
                <div className="nav-outer clearfix">
                  <nav className="main-menu navbar-expand-md navbar-light">
                    <div className="navbar-header">
                      <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="icon-bar"></span>
                        <span className="icon-bar"></span>
                        <span className="icon-bar"></span>
                      </button>
                    </div>
                    <div className="collapse navbar-collapse clearfix" id="navbarSupportedContent">
                      <ul className="navigation clearfix">
                        <li className={`${pathname == '/' && 'current'}`}><Link className='mr-4' to='/'>Home  </Link></li>
                        {/* <li className={`${pathname == '/#about' && 'current'}`}><HashLink className='mr-4' smooth to='/#about'>About</HashLink></li> */}
                        {auth ?
                          <>
                            <li className={`${pathname == '/trading' && 'current'}`}><Link className='mr-4' to='/trading'>Trading</Link></li>
                            <li className={`${pathname == '/buyTex' && 'current'}`}><Link className='mr-4' to='/buyTex'>Buy-TEX</Link></li>
                            <li className={`${pathname == '/myPage' && 'current'}`}><Link className='mr-4' to='/myPage'>My-Page</Link></li>
                            <li><a onClick={logoutButton}>Logout</a></li>
                            {/* <li><a onClick={logout}>Logout</a></li> */}
                          </>
                          :
                          <>

                            <li className={`${pathname === ('/login') && 'current'}`}><Link className='mr-1 btn-login' onClick={() => loginWithRedirect({ redirect_uri: userUrl })}> Signin / Signup</Link></li>
                            {/* <li className={`${pathname === ('/signup') && 'current'}`}><Link className='mr-1 btn-login' onClick={() => loginWithRedirect({screen_hint: "signup"})}> signup </Link></li> */}
                          </>
                        }
                      </ul>
                    </div>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="sticky-header">
          <div className="container-fluid clearfix">
            <div className="nav-inner">
              <div className="logo pull-left">
                <a href="index.html" className="img-responsive"><img src={require("../../static/images/logo-black.png")} alt="" title="" /></a>
              </div>
              <div className="right-col pull-right">
                <nav className="main-menu  navbar-expand-md">
                  <div className="navbar-header">
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent1" aria-controls="navbarSupportedContent1" aria-expanded="false" aria-label="Toggle navigation">
                      <span className="icon-bar"></span>
                      <span className="icon-bar"></span>
                      <span className="icon-bar"></span>
                    </button>
                  </div>
                  <div className="navbar-collapse collapse clearfix" id="navbarSupportedContent1">
                    <ul className="navigation clearfix">
                      <li className="current"><a href="#">Home</a></li>
                      <li><a href="#">About</a></li>
                      <li><a href="#">Portfolio</a></li>
                      <li><a href="#">Pages</a></li>
                      <li><a href="#">Blog</a></li>
                      <li><a href="contact.html">Contact us</a></li>
                    </ul>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  )
}

const mapStateToProps = ({ Auth }) => {
  let { auth } = Auth;
  return { auth };
};
const mapDispatchToProps = { logoutUser };

export default connect(mapStateToProps, mapDispatchToProps)(MainNavbar);