import { updateCoin } from '../sagas/Sockets';

let initialState = {
  selectedCoin: '',

  allCoins: {},
  coinStats: {},

  tradeHistory: [],
  buyOrders: [],
  sellOrders: [],

  openOrders: [],
  orderHistory: [],
  userBalance: {},
};

const Trading = (state = initialState, { type, payload }) => {

  switch (type) {

    case "CHANGE_COIN":
      updateCoin(payload);
      return {
        ...state,
        selectedCoin: payload
      };

    case "SET_ALL_COINS":
      return {
        ...state,
        allCoins: payload
      };

    case "COIN_STATS":
      return {
        ...state,
        coinStats: payload
      };

    case "TRADE_HISTORY":
      return {
        ...state,
        tradeHistory: payload['trades']
      };

    case "BUY_ORDERS":
      return {
        ...state,
        buyOrders: payload['orders']
      };

    case "SELL_ORDERS":
      return {
        ...state,
        sellOrders: payload['orders']
      };

    case "OPEN_ORDERS":
      return {
        ...state,
        openOrders: payload['orders']
      };

    case "ORDER_HISTORY":
      return {
        ...state,
        orderHistory: payload['history']
      };

    case "USER_BALANCE":
      return {
        ...state,
        userBalance: payload['balances']
      };

    default:
      return state;
  }
};

export default Trading;