import React, { Component } from 'react';
import { Button } from 'reactstrap';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import Email from '@material-ui/icons/Email';
import VpnKey from '@material-ui/icons/VpnKey';
import Refresh from '@material-ui/icons/Refresh';
import PersonIcon from '@material-ui/icons/Person';
import IconButton from '@material-ui/core/IconButton';
import RadioGroup from '@material-ui/core/RadioGroup';
import { withStyles } from '@material-ui/core/styles';
import Visibility from '@material-ui/icons/Visibility';
import FormControl from '@material-ui/core/FormControl';
import AccountCircle from '@material-ui/icons/AccountCircle';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';

import MainNavbar from '../../components/MainNavbar';
import { signUpBasic, load } from '../../store/actions/Auth';

import './index.css';

class SignupBasic extends Component {
  constructor() {
    super();
    this.state = {
      formData: {
        name: '',
        // uname: '',
        email: '',
        confirm: '',
        firstName: '',
        lastName: '',
        password: '',
        referralCode: '',
        account: 'personal',
        terms_cond: true
      },
      showConfirm: false,
      showPassword: false,
      passwordMatch: true,
    };
  }

  submitSignup = () => {
    this.props.load(true);
    this.props.signUpBasic({ data: this.state.formData })
    this.setState({formData: {
      email: '',
      confirm: '',
      firstName: '',
      lastName: '',
      password: '',
      account: 'personal',
      terms_cond: true
    }});
  }

  handleFormChange = ({ target }) => {
    const { formData } = this.state;
    formData[target.name] = target.value;
    this.setState({ formData }, () => {
      if (target.name === 'confirm') this.setState({ passwordMatch: this.state.formData.password === this.state.formData.confirm })
    });
  }

  handleMouseDown = (event) => event.preventDefault();
  handleClickShowConfirm = () => this.setState({ showConfirm: !this.state.showConfirm });
  handleClickShowPassword = () => this.setState({ showPassword: !this.state.showPassword });

  render() {
    let { loading } = this.props;
    let { showPassword, showConfirm, passwordMatch } = this.state;
    let { firstName, lastName, email, password, confirm, account, referralCode } = this.state.formData;

    return (
      <>
        <MainNavbar {...this.props} />
        <section className="login-page signup-page">
        {/* <section className="signup-page login-page"> */}
          <div className="container">
            <div className="row">
              <div className="col-12 mt-5">
                <div className="signup-form ">
                  <div className="row clearfix">
                    <div className="col-lg-7 offset-lg-3 1col-md-12 col-sm-12">
                      <div className="form-area">
                        <div className="sec-title text-center">
                          <h2>Sign Up</h2>
                        </div>
                        <ValidatorForm className="validatorForm" onSubmit={this.submitSignup}>
                          {/* <Grid container spacing={1} className='group-input' alignItems="flex-end">
                            <Grid className="signup-fields" item xs={6}>
                              <AccountCircle className='input-icon mr-2' />
                              <CustomTextField
                                fullWidth
                                className="MyTextField"
                                autoComplete='off'
                                label="First Name"
                                name="firstName"
                                type="text"
                                value={firstName}
                                margin="dense"
                                onChange={this.handleFormChange}
                                validators={['required']}
                                errorMessages={['First Name can not be empty']}
                              />
                            </Grid>
                            <Grid className="signup-fields" item xs={6}>
                              <SupervisedUserCircleIcon className='input-icon mt-4 mr-2' />
                              <CustomTextField
                                fullWidth
                                className="MyTextField"
                                autoComplete='off'
                                label="Last Name"
                                name="lastName"
                                type="text"
                                value={lastName}
                                margin="dense"
                                onChange={this.handleFormChange}
                                validators={['required']}
                                errorMessages={['Last Name can not be empty']}
                              />
                            </Grid>
                          </Grid>
                          */}

                          <Grid container spacing={1} className='group-input' alignItems="flex-end">
                            <Grid className="signup-fields" item xs={12}>
                              <Email className='input-icon mt-4 mr-2' />
                              <div className="login-feild-full-width">
                              <CustomTextField
                                fullWidth
                                className="MyTextField"
                                autoComplete='off'
                                label="Email"
                                name="email"
                                type="email"
                                value={email}
                                margin="dense"
                                onChange={this.handleFormChange}
                                validators={['required', 'isEmail']}
                                errorMessages={['Email can not be empty', 'Email is not valid']}
                              />
                              </div>
                             
                            </Grid>
                          </Grid>
                          <Grid container spacing={1} className='group-input' alignItems="flex-end">
                            <Grid className="signup-fields" item xs={12}>
                              <VpnKey className='input-icon mt-4 mr-2' />
                              <div className="login-feild-full-width">
                              <CustomTextField
                                fullWidth
                                name="password"
                                label="Password"
                                value={password}
                                className="MyTextField"
                                validators={['required']}
                                onChange={this.handleFormChange}
                                type={showPassword ? 'text' : 'password'}
                                inputProps={{
                                  autocomplete: 'new-password',
                                  form: { autoComplete: 'off' }
                                }}
                                InputProps={{
                                  endAdornment: <InputAdornment position="end">
                                    <IconButton
                                      style={{ color: '#a80382', marginBottom: '8px' }}
                                      aria-label="toggle password visibility"
                                      onClick={this.handleClickShowPassword}
                                      onMouseDown={this.handleMouseDown}
                                    >
                                      {showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                  </InputAdornment>,
                                }}
                                errorMessages={['Password can not be empty']}
                              />
                              </div>
                             
                            </Grid>
                          </Grid>
                          <Grid container spacing={1} className='group-input' alignItems="flex-end">
                            <Grid className="signup-fields" item xs={12}>
                              <Refresh className='input-icon mt-4 mr-2' />
                              <div className="login-feild-full-width">
                              <CustomTextField
                                fullWidth
                                name="confirm"
                                label="Confirm Password"
                                value={confirm}
                                className="MyTextField"
                                onChange={this.handleFormChange}
                                type={showConfirm ? 'text' : 'password'}
                                inputProps={{
                                  autocomplete: 'new-password',
                                  form: { autoComplete: 'off' }
                                }}
                                InputProps={{
                                  endAdornment: <InputAdornment position="end">
                                    <IconButton
                                      style={{ color: '#a80382', marginBottom: '8px' }}
                                      aria-label="toggle password visibility"
                                      onClick={this.handleClickShowConfirm}
                                      onMouseDown={this.handleMouseDown}
                                    >
                                      {showConfirm ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                  </InputAdornment>,
                                }}
                                error={!passwordMatch}
                                helperText={!passwordMatch && 'Password does not match'}
                                validators={['required']}
                                errorMessages={['Confirm Password cannot be empty']}
                              />
                              </div>
                             
                            </Grid>
                          </Grid>
                          <Grid container spacing={1} className='group-input' alignItems="flex-end">
                            <Grid className="signup-fields" item xs={12}>
                            <ConfirmationNumberIcon className='input-icon mt-4 mr-2' />
                            <div className="login-feild-full-width">
                            <CustomTextField
                                fullWidth
                                className="MyTextField helper"
                                autoComplete='off'
                                label="Referral Code (Optional)"
                                name="referralCode"
                                type="text"
                                value={referralCode}
                                margin="dense"
                                onChange={this.handleFormChange}
                                helperText='If someone reffered you then you can give their refferal code here, or you can leave it empty'
                              />
                            </div>
                           
                            </Grid>
                          </Grid>
                          {/* <Grid container spacing={1} className='group-input' alignItems="flex-end">
                            <Grid className="signup-fields radio-area" item xs={12}>
                              <FormControl component='fieldset'>
                                <RadioGroup className='radio-group' value={account} onChange={this.handleFormChange}>
                                  <FormControlLabel
                                    value="personal"
                                    name="account"
                                    control={<Radio />}
                                    label="Personal Account" />
                                  <FormControlLabel
                                    value="corporate"
                                    name="account"
                                    control={<Radio />}
                                    label="Corporate Account" />
                                </RadioGroup>
                              </FormControl>
                            </Grid>
                          </Grid> */}
                          <div className="submit-box">
                            <Button
                              type="submit"
                              disabled={loading}
                            >
                              {!loading ? 'REGISTER' : <i className="fa fa-spinner fa-spin fa-1x fa-fw" />}
                            </Button>
                          </div>
                          <p>By clicking register, I agree to your <a href="#">Terms</a></p>
                        </ValidatorForm>
                        <div className="already-memmber">
                          <p>Already a member?</p>
                          <Link to='/login' className="login-btn">Login Now</Link>
                        </div>
                      </div>
                    </div>

                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    )
  }
}

const CustomTextField = withStyles({
  root: {
    '& .MuiInputBase-input': {
      color: '#000', // Text color
    },
    '& .MuiInput-underline:before': {
      borderBottomColor: '#000', // Semi-transparent underline
    },
    '& .MuiInput-underline:hover:before': {
      borderBottomColor: '#000', // Solid underline on hover
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#fa6634', // Solid underline on focus
    },
  },
  input: {
    '&:-webkit-autofill': {
      transitionDelay: '9999s',
      transitionProperty: 'background-color, color',
    }
  }
})(TextValidator);

const mapDispatchToProps = { load, signUpBasic };

const mapStateToProps = ({ Auth }) => {
  let { loading } = Auth;
  return { loading };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignupBasic);