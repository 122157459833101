import React, { Component } from 'react';
import EventBus from "eventing-bus";
import { connect } from 'react-redux';
import Error from '@material-ui/icons/Error';
import { withAuth0 } from '@auth0/auth0-react';
import { createBrowserHistory } from "history";
import { ToastContainer, toast } from 'react-toastify';
import CheckCircle from '@material-ui/icons/CheckCircle';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Login from './Login';
import MyPage from './MyPage';
import Forget from './Forget';
import Wallet from './Wallet';
import MagicWallet from './MagicWallet';
import Landing from './Landing';
// import Tex from './Landing/tex';
import Trading from './Trading';
import BuyTEX from './BuyTEX';

import Referral from './Referral';
import ResetPass from './ResetPass';
import VerifyKYC from './VerifyKYC';
import APIKeys from './ApiKeys/index';
import Whitelisting from './Whitelisting';
import PrivateRoute from "../store/PrivateRoute";
import SignupBasic from './Signup/SignupBasic.js';
import SmsAuthentication from './SmsAuthentication';
import SignupAdvance from './Signup/SignupAdvance.js';
import GoogleAuthentication from './GoogleAuthentication';

import { userUrl } from '../store/config';
import { changeCoin } from '../store/actions/Trading';
import { logoutUser, setAuth0Token, loginAuth0 } from '../store/actions/Auth';

import '../static/css/style.css';
import '../static/css/responsive.css';

import 'jquery/dist/jquery.min.js';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';

const hist = createBrowserHistory();
class App extends Component {
  constructor(props) {
    super(props);
    this.state = { };
    props.changeCoin('ETH/BTC');
  }

  async componentDidMount() {
      this.notifications();
    setTimeout(()=> this.tryLogin(), 2000);
  }

  tryLogin = async () => {
    try {
      let {auth, auth0, auth0Token} = this.props;
      let { getAccessTokenSilently, isAuthenticated } = auth0;

      if (isAuthenticated && !auth0Token) {
        let token = await getAccessTokenSilently();

        this.props.setAuth0Token(token);
        if(!auth) this.props.loginAuth0({data: {auth0_token: token}, history: hist});
      } else if(auth0Token && !auth) {
        this.props.loginAuth0({data: {auth0_token: auth0Token}, history: hist});
      }
    } catch (e) {
      EventBus.publish('error', 'Something went wrong with authentication!')
    }
  }

  notifications = () => {
    EventBus.on('tokenExpired', () => {
      let {logout} = this.props.auth0;
      this.props.logoutUser();
      logout({ returnTo: userUrl });
    });
    EventBus.on('error', (e) => toast.error(() => <div> <Error /> {e}</div>));
    EventBus.on('success', (e) => toast.success(() => <div> <CheckCircle /> {e}</div>));
  }

  // async componentWillReceiveProps({ auth0 }) {
  //   try {
  //     let {auth, auth0Token} = this.props;
  //     let { getAccessTokenSilently, isAuthenticated } = auth0;

  //     if (isAuthenticated && !auth0Token) {
  //       let token = await getAccessTokenSilently();
  //       console.log('********Token = ', token);

  //       this.props.setAuth0Token(token);
  //       if(!auth) this.props.loginAuth0({data: {auth0_token: token}, history: hist});
  //     } else if(auth0Token && !auth) {
  //       console.log('***********Token = ', auth0Token);
  //       this.props.loginAuth0({data: {auth0_token: auth0Token}, history: hist});
  //     }
  //   } catch (e) {
  //     console.log('************e = ', e);
  //     EventBus.publish('error', 'Something went wrong with authentication!')
  //   }
  // }

  render() {
    let {mainLoader, mainMessage} = this.props;
    // let {isLoading, isAuthenticated, error, user, loginWithRedirect, logout, getAccessTokenSilently }= this.props.auth0;
    return (
      <div>
        <div hidden={!mainLoader} className="loader-container-main">
            <div className='loader-content-main'>
              <i style={{ color: "white" }} className="fa fa-spinner fa-spin fa-1x fa-fw"></i>
              <p style={{ color: "white", fontSize: "16px", marginTop: "20px" }}>{mainMessage}</p>
            </div>
        </div>
        <ToastContainer
          closeOnClick
          position="bottom-left"
        />
        <Router history={hist}>
          <Switch>
            <Route exact path='/' component={props => <Landing {...props} />} />
            <Route exact path='/kyc_link/:id' component={props => <Landing {...props} />} />
            <Route exact path='/verifyEmail/:id' component={props => <Landing {...props} />} />
            <Route exact path='/verify_withdrawal/:id' component={props => <Landing {...props} />} />
            
            {/* <Route exact path='/buyTex' component={props => <Tex {...props} />} /> */}

            <PrivateRoute exact path='/buyTEX' component={props => <BuyTEX {...props} />} />

            <Route exact path='/forget' component={props => <Forget {...props} />} />
            <Route exact path='/reset_password/:id' component={props => <ResetPass {...props} />} />
            <PrivateRoute path='/magicWallet' component={props => <MagicWallet {...props} /> }/>
            {/* <Route exact path='/signup' component={props => <SignupBasic {...props} />} /> */}
            {/* <Route exact path='/continueSignup/:id' component={props => <SignupAdvance {...props} />} /> */}
            {/* <Route exact path='/login' component={props => <Login {...props} />} /> */}
            <PrivateRoute path='/trading' component={props => <Trading {...props} />} />
            <PrivateRoute path='/myPage' component={props => <MyPage {...props} />} /> {/* Theme Here */}
            <PrivateRoute path='/wallet' component={props => <Wallet {...props} />} /> {/* Theme Here */}

            <Route path='/apiKeys' component={props => <APIKeys {...props} />} /> {/* Theme Here */}

            <PrivateRoute path='/referral' component={props => <Referral {...props} />} /> {/* Theme Here */}
            <PrivateRoute path='/verifyKYC' component={props => <VerifyKYC {...props} />} /> {/* Theme Here */}
            <PrivateRoute path='/whiteListing' component={props => <Whitelisting {...props} />} /> {/* Theme Here */}
            <PrivateRoute path='/smsAuthentication' component={props => <SmsAuthentication {...props} />} /> {/* Theme Here */}
            <PrivateRoute path='/googleAuthentication' component={props => <GoogleAuthentication {...props} />} /> {/* Theme Here */}
          </Switch>
        </Router>
    </div>
    );
  }
}

const mapDispatchToProps = { changeCoin, logoutUser, setAuth0Token, loginAuth0 };
const mapStateToProps = ({ Trading, Auth }) => {
  let { auth, auth0Token, mainLoader, mainMessage } = Auth;
  return { auth, auth0Token, mainLoader, mainMessage };
};

export default connect(mapStateToProps, mapDispatchToProps)(withAuth0(App));