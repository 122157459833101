
export const chartData = {
	theme: "light2",
	animationEnabled: true,
	backgroundColor: "#22212f",
	title: {
		text: "TEX Graph",
		fontColor: "#22212f",
	},
	axisX: {
		intervalType: "month",
		valueFormatString: "MMM-YY",
		labelAngle: -45,
		labelFontColor: '#fff',
		labelFontFamily: "verdana",
		lineColor: "#fff",
	},
	axisY: {
		margin: 20,
		lineThickness: 1,
		lineColor: "#fff",
		labelFontColor: '#fff',
		titleFontFamily: "verdana",
		labelFontFamily: "verdana",
	},
	legend : {
		fontColor: "#fff",
		fontFamily: "verdana",
	 },
	data: [{
		margin: 20,
		type: "candlestick",
		showInLegend: true,
		legendText: "TEX Price",
		color: "#fff",
		risingColor: "#00D100",
		fallingColor: "#FF0000",
		yValueFormatString: "TEX ###0.00",
		xValueFormatString: "MMMM YY",
		dataPoints: [
			{ x: new Date("2017-01-01"), y: [35.61, 28.45, 38.19, 37.82] },
			{ x: new Date("2017-02-01"), y: [36.82, 36.95, 34.84, 36.20] },
			{ x: new Date("2017-03-01"), y: [35.85, 36.30, 34.66, 36.07] },
			{ x: new Date("2017-04-01"), y: [36.19, 37.50, 35.21, 36.15] },
			{ x: new Date("2017-05-01"), y: [36.11, 37.17, 35.02, 36.11] },
			{ x: new Date("2017-06-01"), y: [36.12, 36.57, 33.34, 33.74] },
			{ x: new Date("2017-07-01"), y: [33.51, 35.86, 33.23, 35.47] },
			{ x: new Date("2017-08-01"), y: [35.66, 36.70, 34.38, 35.07] },
			{ x: new Date("2017-09-01"), y: [35.24, 38.15, 34.93, 38.08] },
			{ x: new Date("2017-10-01"), y: [38.12, 45.80, 38.08, 45.49] },
			{ x: new Date("2017-11-01"), y: [45.97, 47.30, 43.77, 44.84] },
			{ x: new Date("2017-12-01"), y: [44.73, 47.64, 42.67, 46.16] }
		]
	}]
}


