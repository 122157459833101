import React, { Component } from 'react';
import { Button } from 'reactstrap';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import VpnKey from '@material-ui/icons/VpnKey';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import AccountCircle from '@material-ui/icons/AccountCircle';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';

import './index.css';
import MainNavbar from '../../components/MainNavbar';
import { load, forgotPassword } from '../../store/actions/Auth';

class Forgot extends Component {
  constructor() {
    super();
    this.state = {
      formData: {
        email: ''
      }
    };
  }

  submitForgot = () => {
    this.props.load(true);
    this.props.forgotPassword(this.state.formData)
  }

  handleFormChange = ({ target }) => {
    const { formData } = this.state;
    formData[target.name] = target.value;
    this.setState({ formData });
  }

  render() {
    let { loading } = this.props;
    let { email } = this.state.formData;
    return (
      <>
        <MainNavbar {...this.props}/>
        <section className="forget-page">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="forget-form">
                  <div className="row clearfix">
                    <div className="col-lg-6 offset-lg-3 col-md-12 col-sm-12">
                      <div className="form-area">
                        <ValidatorForm className="validatorForm" onSubmit={this.submitForgot}>
                          <Grid container spacing={1} className="group-input" alignItems="flex-end">
                            <Grid item xs={1}>
                              <AccountCircle className='input-icon text-white' />
                            </Grid>
                            <Grid item xs={11}>
                              <CustomTextField
                                fullWidth
                                className="MyTextField"
                                autoComplete='off'
                                label="Email"
                                name="email"
                                type="text"
                                value={email}
                                margin="dense"
                                onChange={this.handleFormChange}
                                validators={['required', 'isEmail']}
                                errorMessages={['Email can not be empty', 'Email is not valid']}
                              />
                            </Grid>
                          </Grid>
                          <div className="group-form submit-box">
                            <Button
                              type="submit"
                              disabled={loading}
                            >
                              {!loading ? 'Reset Password' : <i className="fa fa-spinner fa-spin fa-1x fa-fw" />}
                            </Button>
                          </div>
                        </ValidatorForm>
                      </div>
                      <div className="already-memmber">
                        <p>Already a member?</p>
                        <Link to='/login' className="login-btn">Login Now</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    )
  }
}

const CustomTextField = withStyles({
  root: {
    '& .MuiInputBase-input': {
      color: '#fff', // Text color
    },
    '& .MuiInput-underline:before': {
      borderBottomColor: '#fff', // Semi-transparent underline
    },
    '& .MuiInput-underline:hover:before': {
      borderBottomColor: '#fff', // Solid underline on hover
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#fa6634', // Solid underline on focus
    },
  },
  input: {
    '&:-webkit-autofill': {
      transitionDelay: '9999s',
      transitionProperty: 'background-color, color',
    }
  }
})(TextValidator);

const mapDispatchToProps = { load, forgotPassword };

const mapStateToProps = ({ Auth }) => {
  let { loading } = Auth;
  return { loading };
};

export default connect(mapStateToProps, mapDispatchToProps)(Forgot);