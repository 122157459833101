import { updateID } from '../sagas/Sockets';

let initialState = {
  isPaypal: true,
  isTEXHistory: false,
  buyTEXHistory: [],
  graphTexData: [],
  priceTexData: {},
};

const TEX = (state = initialState, { type, payload }) => {

  switch (type) {
    case "TOGGLE_PAYPAL_CHECKOUT":
      return {
        ...state,
        isPaypal: payload,
      };

    case "TOGGLE_TEX_HISTORY":
      return {
        ...state,
        isTEXHistory: true,
      };

    case "SET_BUY_TEX_HISTORY":
      return {
        ...state,
        buyTEXHistory: payload['orders'],
      };

    case "SET_TEX_GRAPH":
      let graphTexData = [];
      payload.forEach(point => {
        graphTexData.push({ x: new Date(point['x']), y: point['y'] })
      });
      return {
        ...state,
        graphTexData,
      };

    case "SET_TEX_PRICE":
      return {
        ...state,
        priceTexData: payload,
      };

    default:
      return state;
  }
};

export default TEX;