import '../axios';
import Auth from './Auth';
import Sockets from './Sockets';
import Trading from './Trading';
import Wallet from './Wallet';
import TEX from './TEX';

import { all } from 'redux-saga/effects';

export default function* rootSaga() {
  yield all([
    Auth(),
    Trading(),
    Wallet(),
    TEX(),
    Sockets(),
  ]);
}
