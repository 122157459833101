import moment from 'moment';
import EventBus from 'eventing-bus';
import { connect } from 'react-redux';
import React, { Component, Fragment } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { AppMode } from '../../../store/config';
import AppLoader from '../../../components/AppLoader';
import NumberFormat from '../../../components/NumberFormat';
import { getCoinWallet, getDepositHistory, setCoin } from '../../../store/actions/Wallet';

class Fiat extends Component {
  constructor(props) {
    super(props);
    this.state = {
      copied: true,
      walletList: [],
      type: 'Deposit',
      selectedWallet: props.selectedWallet,
    };
    props.getCoinWallet(props.selectedWallet);
    props.getDepositHistory({ type: this.state.type, symbol: props.selectedWallet });
  }

  componentWillReceiveProps({ selectedWallet, wallets, coinWallet }) {
    if (selectedWallet !== this.state.selectedWallet) {
      this.setState({ selectedWallet });
      this.props.getCoinWallet(selectedWallet);
      this.props.getDepositHistory({ type: this.state.type, symbol: selectedWallet })
    }
    this.setState({ walletList: wallets.filter(({ symbol, category }) => symbol !== selectedWallet && category === 'crypto') });

    if (coinWallet['symbol'] == 'BTC') this.setState({ selectedCoin: 'bitcoin' });
    else if (coinWallet['symbol'] == 'ETH') this.setState({ selectedCoin: 'ethereum' });
    else if (coinWallet['symbol'] == 'USDT') this.setState({ selectedCoin: 'tether' });
  }

  copied = () => EventBus.publish("success", 'Wallet Address Copied');

  render() {
    let { walletList, selectedCoin } = this.state;
    let { coinWallet, coinLoader } = this.props;
    let coinData = coinWallet['coinId'] || {};
    return (
      <>
        <div class="row clearfix">
          <div class="col-12 d-flex justify-content-center">
            <div class="deposit-fiat">
              <div className="mt-4 deposit-fiat-title"><h5>Desposit Fiat</h5></div>
              {coinLoader
                ? <div className='pt-5 mt-5'><AppLoader message={`Generating your wallet address`} /></div> :
                <div class="payment-sec qr-sec">
                  <div class="accordion md-accordion" id="accordionEx" role="tablist" aria-multiselectable="true">
                    <div class="card">
                      <h6 className='text-white my-2 mr-3 d-flex justify-content-end'>
                        Balance:  <NumberFormat value={coinWallet['confirmedBalance']} coin={coinWallet['symbol']} /> {coinWallet['symbol']}
                      </h6>
                      <div class="card-header" role="tab" id="headingOne1">
                        <div class="btn-group">
                          <button type="button" class="btn btn-secondary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <span class="icon-left">
                              <img src={coinData['icon']} className="dropdown-icon" alt="Bitcoin Logo" />
                            </span>
                            {coinData['coinName']} ({coinData['symbol']})
                        </button>
                          <div class="dropdown-menu">
                            {walletList.map(wallet => (
                              <button class="dropdown-item" type="button" onClick={() => this.props.setCoin(wallet['symbol'])}>
                                <span class="icon-left">
                                  <img src={wallet['icon']} className='dropdown-icon' alt="Bitcoin Logo" />
                                </span>
                                {wallet['name']} ({wallet['symbol']})
                              </button>
                            ))}
                          </div>
                        </div>
                      </div>
                      <div class="card-body">
                        <div class="top-copy-area">
                          <h4>Deposit Address:</h4>
                          <div class="copyext">{coinWallet['address']} </div>
                          {coinWallet['address'] && <CopyToClipboard text={coinWallet['address']} onCopy={this.copied}>
                            <button class="btn">Copy</button>
                          </CopyToClipboard>}
                        </div>
                        <div class="connect-wyre qr-reading">
                          <div className="row mt-5 deposit-fiat-btn">
                            <a className="connect-btn" target="_blank" href={`https://pay.sendwyre.com/purchase?dest=${selectedCoin}:${coinWallet['address']}&destCurrency=${coinWallet['symbol']}&accountId=AC-7AG3W4XH4N2&paymentMethod=debit-card`} >
                              Connect to Wyre
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      </>
    )
  }
}

const mapDispatchToProps = { getCoinWallet, getDepositHistory, setCoin };

const mapStateToProps = ({ Wallet }) => {
  let { selectedWallet, coinWallet, wallets, coinLoader } = Wallet;
  return { selectedWallet, coinWallet, wallets, coinLoader };
};

export default connect(mapStateToProps, mapDispatchToProps)(Fiat);