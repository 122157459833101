import jwt_decode from 'jwt-decode';
import { eventChannel } from 'redux-saga';
import { take, call, put, all, takeLeading, fork } from 'redux-saga/effects';

import { socket } from '../config';
import { landingPageData, setUserKYCID } from '../actions/Auth';
import { setBuyTexHistory, toggleTexHistory, setTexGraph, setTexPrice } from '../actions/TEX';
import { setAllCoins, coinStats, tradeHistory, buyOrders, sellOrders, openOrders, orderHistory, userBalance, } from '../actions/Trading';

let id, selectedCoin = '';
let token = localStorage.getItem('token');
if (token) id = (jwt_decode(token))['_id'];

export const updateID = (newId) => id = newId;
export const updateCoin = (coin) => selectedCoin = coin;

const createSocket = socket => eventChannel((emit) => {

    //********** /* LANDING PAGE SOCKETS */ **********//

    socket.on('landing:coins', data => emit(landingPageData(data)));

    //********** /* VERIFY KYC SOCKETS */ **********//

    socket.on('user:kyc:submitted', userId => emit(setUserKYCID(userId)));

    //********** /* TRADING PAGE SOCKETS */ **********//

    /* COINS SOCKETS */
    /************** All Pairs Prices  **************/
    socket.on(`all_prices`, data => {
        emit(setAllCoins(data));
        return;
    });

    /************** Single Pair Price **************/
    socket.on(`coin_price`, data => {
        if (data['symbol'] === selectedCoin) emit(coinStats(data));
        return;
    });

    /************** Trading History of Pair **************/
    socket.on(`spot:trade:history`, (data) => {
        if (data['pair'] === selectedCoin) emit(tradeHistory(data));
        return;
    });

    /************** Buy Orders of Pair **************/
    socket.on('spot:orders:buy', (data) => {
        if (data['pair'] === selectedCoin) emit(buyOrders(data));
        return;
    });

    /************** Sell Orders of Pair **************/
    socket.on('spot:orders:sell', data => {
        if (data['pair'] === selectedCoin) emit(sellOrders(data));
        return;
    });


    /* USERS SOCKETS */
    /**************  Open Orders of User  **************/
    socket.on('spot:orders:open', data => {
        if (id == data['userId']) emit(openOrders(data));
        return;
    });

    /**************  History of User  **************/
    socket.on('spot:order:history', data => {
        if (id == data['userId']) emit(orderHistory(data));
        return;
    });

    /**************  Balance of User  **************/
    socket.on('spot:balance', data => {
        if (id == data['userId']) emit(userBalance(data));
        return;
    })

    //********** /* BUY TEX SOCKETS */ **********//

    /************** Deposit History  **************/
    socket.on('silver:orders', data => {
        if (id == data['userId']) {
            emit(setBuyTexHistory(data));
            emit(toggleTexHistory());
            return;
        } 
    });

    /************** TEX Graph  **************/
    socket.on('silver:graph', data => {
        emit(setTexGraph(data));
        return;
    });

    /************** TEX Price  **************/
    socket.on('silver:price', data => {
        emit(setTexPrice(data));
        return;
    }); 


    return () => {
        socket.off(`all_prices`, () => emit());
        socket.off('coin_price', () => emit());
        socket.off(`spot:trade:history`, () => emit());
    }
});

export default function* listenSockets() {
    const socketChannel = yield call(createSocket, socket);
    while (true) {
        let action = yield take(socketChannel);
        if (action) yield put(action);
    }
};

// const disconnect = () => {
//   socket = io(SocketUrl);
//   return new Promise((resolve) => socket.on('disconnect', () => resolve(socket)));
// };

// const reconnect = () => {
//   socket = io(SocketUrl);
//   return new Promise((resolve) => socket.on('reconnect', () => resolve(socket)));
// };