import jwt_decode from 'jwt-decode';
import { updateID } from '../sagas/Sockets';
import { updateIDTEX } from '../sagas/TEX';
import { setToken, setAuth0Token } from '../axios';

let initialState = {
  id: '',
  auth: localStorage.getItem('token'),
  auth0Token: localStorage.getItem('auth0Token'),
  isResendEmail: false,
  kycData: '',
  kycID: '',
  isKYCModal: false,
  userAuth: {},
  googleAuth: {},
  loading: false,
  lightMode: false,
  // lightMode: true,
  userDetails: {},
  loginHistory: [],
  isUserAuthModal: false,
  isModalAntiPhishing: false,
  landingBannerData: {},
  landingTableData: {},
  apiKeyLoader: false,
  apiKeys: [],
  whitelistedAddress: [],
  isWhitelistAdded: false,
  allReferrals: [],
  referralCode: '',
  mainLoader: false,
  mainMessage: '',
  buyTEXHistory: [],
  magicValues: [],
  onBoard: {},
  authorization: [],
  createCertificate:''
};

const Auth = (state = initialState, { type, payload }) => {

  switch (type) {

    case "SET_AUTH0_TOKEN":
      setAuth0Token(payload);
      localStorage.setItem('auth0Token', payload);
      return {
        ...state,
        auth0Token: payload
      };

    case "USER_DATA":
      let id = (jwt_decode(payload['token']))['_id'];
      localStorage.setItem('token', payload['token']);

      updateID(id);
      updateIDTEX(id);
      setToken(payload['token']);
      return {
        ...state,
        id,
        auth: payload['token'],
      };

    case "USER_AUTH":
      return {
        ...state,
        userAuth: payload,
      };

    case "TOGGLE_RESEND_EMAIL":
      return {
        ...state,
        isResendEmail: !state['isResendEmail'],
      };

    case "SET_MAIN_LOADER":
      return {
        ...state,
        mainLoader: payload['mainLoader'],
        mainMessage: payload['mainMessage'],
      };

    case "SET_KYC_DATA":
      return {
        ...state,
        kycData: payload,
      };

    case "TOGGLE_AUTH_MODAL":
      return {
        ...state,
        isUserAuthModal: !state['isUserAuthModal'],
      };

    case 'GOOGLE_AUTH_SETTINGS':
      return {
        ...state,
        googleAuth: payload
      };

    case 'LOGOUT':
      setToken();
      setAuth0Token();
      localStorage.removeItem('token');
      localStorage.removeItem('auth0Token');
      return {
        ...state,
        auth: ''
      };

    case "CHANGE_MODE":
      return {
        ...state,
        lightMode: payload
      };

    case 'LOADING':
      return {
        ...state,
        loading: payload
      };

    case 'MY_PAGE_DATA':
      return {
        ...state,
        userDetails: payload ? payload['user'] : {},
        loginHistory: payload ? payload['logging'] : [],
      };

    case 'MY_PAGE_USER_DATA':
      return {
        ...state,
        userDetails: payload ? payload : {},
      };

    case "TOGGLE_MODAL_PHISHING":
      return {
        ...state,
        isModalAntiPhishing: !state['isModalAntiPhishing'],
      };

    case "LANDING_PAGE_DATA":
      return {
        ...state,
        landingBannerData: payload['bannerCoin'],
        landingTableData: payload['tableCoins']
      };

    case "SET_USER_KYC_ID":
      return {
        ...state,
        kycID: payload,
      };

    case "TOGGLE_KYC_MODAL":
      return {
        ...state,
        isKYCModal: !state['isKYCModal'],
      };

    case "API_KEYS_LOADER":
      return {
        ...state,
        apiKeyLoader: payload,
      };

    case "SET_API_KEYS":
      return {
        ...state,
        apiKeys: payload,
      };

    case "SET_WHITELISTED_ADDRESS":
      return {
        ...state,
        whitelistedAddress: payload,
      };

    case "WHITELIST_ADDED":
      return {
        ...state,
        isWhitelistAdded: payload,
      };

    case "SET_REFERRALS":
      return {
        ...state,
        allReferrals: payload['allReferals'],
        referralCode: payload['refferalCode'],
      };

    case "SET_BUY_TEX_HISTORY":
      return {
        ...state,
        buyTEXHistory: payload,
      };

    case "SET_MAGIC_VALUES":
      return {
        ...state,
        magicValues: payload,
      }
    case "SET_ONBOARD_VALUES":
      return {
        ...state,
        onBoard: payload
      }

    case "SET_AUTHORIZATION_VALUES":
      return {
        ...state,
        authorization: payload
      }

    case "SET_CERATE_CERTIFICATE":
      return {
        ...state,
        createCertificate: payload
      }

    default:
      return state;
  }
};

export default Auth;