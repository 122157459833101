import { connect } from 'react-redux';
import React, { Component } from 'react';

import './loader.css';
import { css } from "@emotion/core";
import BounceLoader from "react-spinners/BounceLoader";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

class AppLoader extends Component {

  render() {
    let { lightMode, message } = this.props;
    return (
      <div className={`${lightMode && 'light'}`}>
        <div className='loader'>
          <div className="loader-container">
            <BounceLoader
              css={override}
              size={50}
              color={`${lightMode == "dark" ? "#5c0b49" : "#a80382"}`}
              loading={true}
            />
          </div>
          {message && <div className="loader-container"> <span className="loading-text">{message}</span></div>}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = {};

const mapStateToProps = ({ Auth }) => {
  let { lightMode } = Auth;
  return { lightMode };
};

export default connect(mapStateToProps, mapDispatchToProps)(AppLoader);
