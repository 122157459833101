import { connect } from "react-redux";
import React, { Component } from "react";
import { AuthorizationValues } from "../../../store/actions/Auth";

class Authorization extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoadingAuthorization: false,
            userAuthorization: false,
        }
    }

    componentWillReceiveProps({ userDetails }) {
        if (userDetails['step'] === 'authorized') this.setState({ isLoadingAuthorization: false, userAuthorization: true });
    };

    onAuthorized = (item) => {
        this.setState({ isLoadingAuthorization: true })
        this.props.AuthorizationValues({ "uuid": item })
    };

    render() {
        const { isLoadingAuthorization, userAuthorization } = this.state
        const { userDetails, handleNext } = this.props
        return (
            <>
                <div className="inner-box">
                    <div className="logo-area">
                        <img src={require("../../../static/images/TEX-Wallet.jpeg")} alt="" />
                    </div>
                    <div className="content-box">
                        <h2>Authorization</h2>
                        {!userAuthorization && <h5>Connect Via</h5>}
                        {userAuthorization === false
                            ? isLoadingAuthorization === false
                                ? <div className="verfiy-box" onClick={() => this.onAuthorized(this.props.userDetails['uuid'])}>
                                    <img src={require("../../../static/images/steps/9.png")} alt="" />
                                    <div className="overlay-box overlay-box-auth">
                                        <img src={require("../../../static/images/steps/8.png")} alt="" />
                                        <h3>Authorize</h3>
                                    </div>
                                </div>
                                : <div className="verfiy-box">
                                    <img src={require("../../../static/images/steps/9.png")} alt="" />
                                    <div className="overlay-box overlay-auth-waiting">
                                        <i className="waiting-loader fas fa-spinner fa-spin"></i>
                                        <h3> Wait...</h3>
                                    </div>
                                </div>
                            : <div className="form-box">
                                <form>
                                    <div className="group-form text-left">
                                        <button onClick={() => this.props.handleNext()}>Next <i className="icon"><img src={require("../../../static/images/steps/7.png")} alt="" /></i></button>
                                    </div>
                                </form>
                            </div>
                        }
                        <p>Aenean id porttitor purus, et cursus augue. Vestibulum nibh justo, molestie in dictum in, posuere non tellus. Etiam dolor purus, porta eut</p>
                    </div>
                </div>
            </>
        );
    }
}

const mapDispatchToProps = {
    AuthorizationValues
};

const mapStateToProps = ({ Auth }) => {
    let { userDetails, authorization } = Auth;
    return { userDetails, authorization };
};

export default connect(mapStateToProps, mapDispatchToProps)(Authorization);