var initialState = {
  overview: {},
  wallets: [],
  coinWallet: {},
  selectedWallet: 'BTC',

  depositHistory: [],
  depositFiatHistory: [],

  withdrawHistory: [],
  withdrawFiatHistory: [],

  isHistoryLoading: false,

  coinLoader: false,
  balanceLoader: true,
  depositLoader: false,
  withdrawLoader: false,
  withdrawHistoryLoader: false,

  depositDetails: {},
  isWithdrawEUR: false,
};

const Wallet = (state = initialState, { type, payload }) => {

  switch (type) {
    case "SET_WALLET_BALANCE":
      return {
        ...state,
        overview: payload['overview'],
        wallets: payload['wallets'],
      };

    case "SET_COIN":
      return {
        ...state,
        selectedWallet: payload,
      };

    case "SET_COIN_WALLET":
      return {
        ...state,
        coinWallet: payload,
      };

    case "SET_DESPOSIT_HISTORY":
      return {
        ...state,
        depositHistory: payload['data'],
      };

    case "SET_DESPOSIT_FIAT_HISTORY":
      return {
        ...state,
        depositFiatHistory: payload,
      };

    case "SET_WITHDRAW_HISTORY":
      return {
        ...state,
        withdrawHistory: payload['data'],
      };

    case "SET_WITHDRAW_FIAT_HISTORY":
      return {
        ...state,
        withdrawFiatHistory: payload,
      };

    case "SET_HISTORY_LOADER":
      return {
        ...state,
        isHistoryLoading: payload
      }
    case "SET_BALANCE_LOADER":
      return {
        ...state,
        balanceLoader: payload,
      };

    case "SET_DEPOSIT_LOADER":
      return {
        ...state,
        depositLoader: payload,
      };

    case "SET_WITHDRAW_HISTORY_LOADER":
      return {
        ...state,
        withdrawHistoryLoader: payload,
      };

    case "SET_WITHDRAW_LOADER":
      return {
        ...state,
        withdrawLoader: payload,
      };

    case "SET_COIN_LOADER":
      return {
        ...state,
        coinLoader: payload,
      };

    case "SET_DEPOSIT_DETAILS":
      return {
        ...state,
        depositDetails: payload,
      };

    case "WITHDRAW_EUR_LOADER":
      return {
        ...state,
        isWithdrawEUR: payload,
      };

    default:
      return state;
  }
};

export default Wallet;