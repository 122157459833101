export const getWalletBalance = () => ({
  type: 'GET_WALLET_BALANCE',
});

export const setWalletBalance = (data) => ({
  type: 'SET_WALLET_BALANCE',
  payload: data
});

export const getCoinWallet = (data) => ({
  type: 'GET_COIN_WALLET',
  payload: data
});

export const setCoinWallet = (data) => ({
  type: 'SET_COIN_WALLET',
  payload: data,
});

export const setCoin = (data) => ({
  type: 'SET_COIN',
  payload: data
});

export const getDepositHistory = (data) => ({
  type: 'GET_DESPOSIT_HISTORY',
  payload: data
});

export const setDepositHistory = (data) => ({
  type: 'SET_DESPOSIT_HISTORY',
  payload: data
});

export const getDepositFiatHistory = () => ({
  type: 'GET_DESPOSIT_FIAT_HISTORY',
});

export const setDepositFiatHistory = (data) => ({
  type: 'SET_DESPOSIT_FIAT_HISTORY',
  payload: data
});

export const getWithdrawHistory = (data) => ({
  type: 'GET_WITHDRAW_HISTORY',
  payload: data
});

export const setWithdrawHistory = (data) => ({
  type: 'SET_WITHDRAW_HISTORY',
  payload: data
});

export const getWithdrawFiatHistory = () => ({
  type: 'GET_WITHDRAW_FIAT_HISTORY',
});

export const setWithdrawFiatHistory = (data) => ({
  type: 'SET_WITHDRAW_FIAT_HISTORY',
  payload: data
});

export const setHistoryLoader = (data) => ({
  type: 'SET_HISTORY_LOADER',
  payload: data
});


export const withdrawalRequest = (data) => ({
  type: 'WITHDRAWAL_REQUEST',
  payload: data
});

export const verifyWithdrawal = ({ data, history }) => ({
  type: 'VERIFY_WITHDRAWAL',
  payload: data,
  history
});

export const setBalanceLoader = (data) => ({
  type: 'SET_BALANCE_LOADER',
  payload: data
});

export const setDepositLoader = (data) => ({
  type: 'SET_DEPOSIT_LOADER',
  payload: data
});

export const setWithdrawHistoryLoader = (data) => ({
  type: 'SET_WITHDRAW_HISTORY_LOADER',
  payload: data
});

export const cancelWithdraw = (data) => ({
  type: 'CANCEL_WITHDRAW',
  payload: data
});

export const setWithdrawLoader = (data) => ({
  type: 'SET_WITHDRAW_LOADER',
  payload: data
});

export const setCoinLoader = (data) => ({
  type: 'SET_COIN_LOADER',
  payload: data
});

export const getDepositDetails = (data) => ({
  type: 'GET_DEPOSIT_DETAILS',
});

export const setDepositDetails = (data) => ({
  type: 'SET_DEPOSIT_DETAILS',
  payload: data
});

export const withdrawEUR = (data) => ({
  type: 'WITHDRAW_EUR',
  payload: data
});

export const withdrawEURLoader = (data) => ({
  type: 'WITHDRAW_EUR_LOADER',
  payload: data
});

// export const setCFiatLoader = (data) => ({
//   type: 'SET_FIAT_LOADER',
//   payload: data
// });