import EventBus from 'eventing-bus';
import { connect } from "react-redux";
import React, { Component } from "react";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Fragment } from 'react';
import { magic } from '../../../store/web3';
import { magicValues } from "../../../store/actions/Auth";

class Wallet extends Component {
    constructor(props) {
        super(props);
        this.state = {
            magicAddress: '',
            email: '',
            isConnect: true,
            isLoggedIn: false,
            IsLoadingWallet: true,
        }
    };

    componentDidMount() {
        let { userDetails } = this.props;
        if (userDetails['magicAddress'] == undefined) { this.getWallet(); }
    }

    componentWillReceiveProps({ userDetails }) {
        if (userDetails['magicAddress'] !== undefined) {
            this.setState({ magicAddress: userDetails['magicAddress'], email: userDetails['email'] });
            if (userDetails['magicAddress']) { this.setState({ isConnect: false, isLoggedIn: true, IsLoadingWallet: false }) }
        }
    };

    getWallet = async () => {
        const isLoggedIn = await magic.user.isLoggedIn();
        if (isLoggedIn !== false) {
            const userMetadata = await magic.user.getMetadata();
            this.props.magicValues({ magicDid: userMetadata.issuer, magicAddress: userMetadata.publicAddress, step: 'wallet' })
            setTimeout(async () => await magic.user.logout(), 20000)
        } else { this.setState({ IsLoadingWallet: false }) }
    }

    handleLogin = async (email) => {
        const redirectURI = `${window.location.origin}/magicWallet`; // This will be our callback URI
        if (email) {
            this.setState({ IsLoadingWallet: true });
            let response = await magic.auth.loginWithMagicLink({ email, redirectURI }); //  Notice the additional parameter!
            if(response){this.getWallet();}
        }
    }

    addressCopied = () => EventBus.publish("success", 'Magic Address Copied');

    emailCopied = () => EventBus.publish("success", 'Email Copied');

    render() {
        let { isConnect, magicAddress, email, IsLoadingWallet, isLoggedIn } = this.state;
        const { userDetails } = this.props;
        return (
            <>
                <div class="inner-box">
                    <div class="logo-area">
                        <img  src={require("../../../static/images/TEX-Wallet.jpeg")} alt="" />
                    </div>
                    <div class="content-box">
                        <h2>Connect Wallet</h2>
                        <h5>{isConnect ? 'Connect' : 'Connected'} Via</h5>
                        <div class="verfiy-box">
                            {isConnect && <img src={require("../../../static/images/steps/1.png")} alt="" onClick={() => this.handleLogin(userDetails['email'])} />}
                            {isLoggedIn !== true ?
                                <div className={`overlay-box ${IsLoadingWallet == false && 'overlay-box-wallet'}`} onClick={() => this.handleLogin(userDetails['email'])}>
                                    {IsLoadingWallet == false ? <h4>Using Email</h4> : null}
                                    {IsLoadingWallet == false
                                        ? <img src={require("../../../static/images/steps/2.png")} alt="" />
                                        : <i className="waiting-loader fas fa-spinner fa-spin"></i>
                                    }
                                    <h3>{IsLoadingWallet == false ? 'Wallet' : ' Wait...'}</h3>
                                </div>
                                : <div className="form-box">
                                    <form>
                                        <div class="group-form">
                                            <input value={(magicAddress)?.substring(0, 25) + '...'} disabled={true} type="text" name="address" placeholder="Address" />
                                            <CopyToClipboard text={magicAddress} onCopy={this.addressCopied}>
                                                <span class="icon"><img src={require("../../../static/images/steps/6.png")} alt="" /></span>
                                            </CopyToClipboard>
                                        </div>
                                        <div class="group-form">
                                            <input value={email} disabled={true} type="text" name="email" placeholder="Email" />
                                            <CopyToClipboard text={email} onCopy={this.emailCopied}>
                                                <span class="icon"><img src={require("../../../static/images/steps/6.png")} alt="" /></span>
                                            </CopyToClipboard>
                                        </div>
                                        <div class="group-form text-right">
                                            <button onClick={() => this.props.handleNext()}>Next <i class="icon"><img src={require("../../../static/images/steps/7.png")} alt="" /></i></button>
                                        </div>
                                    </form>
                                </div>
                            }
                        </div>
                        <p>Aenean id porttitor purus, et cursus augue. Vestibulum nibh justo, molestie in dictum in, posuere non tellus. Etiam dolor purus, porta eut</p>
                    </div>
                </div>
            </>
        );
    }
}

const mapDispatchToProps = {
    magicValues
};

const mapStateToProps = ({ Auth }) => {
    let { userDetails } = Auth;
    return { userDetails };
};
export default connect(mapStateToProps, mapDispatchToProps)(Wallet);