import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { AppContainer } from 'react-hot-loader';
import * as serviceWorker from './serviceWorker';
import { Auth0Provider } from "@auth0/auth0-react";

import { logger } from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
import { createStore, applyMiddleware } from 'redux';

import App from './app';
import rootSaga from './store/sagas';
import { userUrl } from './store/config';
import rootReducer from './store/reducers';

serviceWorker.unregister();
const sagaMiddleware = createSagaMiddleware();
const store = createStore(rootReducer, applyMiddleware(sagaMiddleware, logger));

const { REACT_APP_AUTH0_DOMAIN, REACT_APP_AUTH0_CLIENT_ID } = process['env'];

sagaMiddleware.run(rootSaga);

render(
  <Provider store={store}>
    <AppContainer>
      <Auth0Provider
        redirectUri={userUrl}
        domain={REACT_APP_AUTH0_DOMAIN}
        clientId={REACT_APP_AUTH0_CLIENT_ID}
      >
        <App />
      </Auth0Provider>
    </AppContainer>
  </Provider>,
  document.getElementById('root')
);

if (module.hot) module.hot.accept(App);