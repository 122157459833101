import axios from 'axios';
import moment from "moment";
import EventBus from "eventing-bus";
import { connect } from 'react-redux';
import React, { Component, Fragment } from 'react';
import PaypalExpressBtn from 'react-paypal-express-checkout';

import './index.css';
import { chartData } from './data.js';
import { AppMode } from '../../store/config';
import Navbar from "../../components/Navbar";
import { myPage } from "../../store/actions/Auth";
import AppLoader from "../../components/AppLoader";
import NumberFormat from '../../components/NumberFormat';
import CanvasJSReact from '../../static/js/canvasjs.react';
import { buyTEXSockets, paypalCheckout, togglePaypalCheckout, toggleTexHistory } from '../../store/actions/TEX';

let CanvasJSChart = CanvasJSReact.CanvasJSChart;
const { REACT_APP_SANDBOX_PAYPAL, REACT_APP_PRODUCTION_PAYPAL, REACT_APP_METALS_API_KEY } = process['env'];

class BuyTEX extends Component {

    constructor(props) {
        super(props);
        this.state = {
            interval: '',
            spend: '',
            calculate: '',
            priceStats: {},
            isRerender: true,
            paymentOptions: {
                invoice_number: '12323'
            },
            paypal: {
                // env: 'production',
                env: AppMode[0] === 'production' ? 'production' : 'sandbox',
                currency: 'USD',
                style: {
                    color: 'silver',
                    size: 'medium',
                    height: 43,
                    tagline: false
                },
                client: {
                    // sandbox: REACT_APP_SANDBOX_PAYPAL,
                    sandbox: 'Abxz1K9XkcAyTIiOgDRn5k3v6vfSOCJJKXmz7norEwOb2zOPXE9JFNV-wxX_KLQuuZB3FkUTrVeVE8Zu',
                    production: 'AdopT_XLfsG_xANtSTW36PJYIp3eHIkYl6eoks1qBBuyjIHv-MCz7IqTvxeXueLau7A1Gy-KgQimwyYi',
                },
                currentItems: 0
            },
        };
        props.buyTEXSockets();
        props.toggleTexHistory();
    };

    componentDidMount() {
        this.setState({ currentItems: 5 })
        this.rateTEX();

        var interval = setInterval(() => { this.props.myPage() }, 5000);

        this.setState({ interval })
    };

    componentWillReceiveProps({ lightMode, graphTexData, priceTexData }) {
        this.setState({ priceStats: priceTexData });
        if (this.chart.options['data']) {
            this.chart.options['data'][0]['dataPoints'] = [];
            this.chart.options['data'][0]['dataPoints'] = graphTexData
            this.chart.render();
        };

        if (lightMode) {
            this.setState({ isRerender: false }, () => {
                let { paypal } = this.state;
                paypal['style']['color'] = 'gold';
                this.setState({ paypal, isRerender: true },
                )
            });
            this.chart.options['backgroundColor'] = '#ffffff';
            this.chart.options['title']['fontColor'] = '#ffffff';
            this.chart.options['axisX']['labelFontColor'] = '#22212f';
            this.chart.options['axisX']['lineColor'] = '#22212f';
            this.chart.options['axisY']['labelFontColor'] = '#22212f';
            this.chart.options['axisY']['lineColor'] = '#22212f';
            this.chart.options['legend']['fontColor'] = '#22212f';
            this.chart.options['data']['0']['color'] = '#22212f';
            this.chart.render();
        }

        else {
            this.setState({ isRerender: false }, () => {
                let { paypal } = this.state;
                paypal['style']['color'] = 'silver';
                this.setState({ paypal, isRerender: true }
                )
            });
            this.chart.options['backgroundColor'] = '#22212f';
            this.chart.options['title']['fontColor'] = '#22212f';
            this.chart.options['axisX']['labelFontColor'] = '#ffffff';
            this.chart.options['axisX']['lineColor'] = '#ffffff';
            this.chart.options['axisY']['labelFontColor'] = '#ffffff';
            this.chart.options['axisY']['lineColor'] = '#ffffff';
            this.chart.options['legend']['fontColor'] = '#ffffff';
            this.chart.options['data']['0']['color'] = '#ffffff';
            this.chart.render();
        }
    };

    LoadMoreItems = () => {
        this.setState({ currentItems: this.state.currentItems + 5 })
    }

    componentWillUnmount() {
        // use intervalId from the state to clear the interval
        clearInterval(this.state.interval);
    }

    rateTEX = async () => {
        try {
            const response = await axios.get(`https://metals-api.com/api/latest?access_key=${REACT_APP_METALS_API_KEY}&base=XAG&symbols=USD`);
            const calculate = response.data.rates.USD / 28.3495;
            this.setState({ calculate });
        }
        catch (e) {
            console.log("*******ERROR::", e);
            EventBus.publish("error", "TEX Rates Error");
        }
    };

    onSuccess = (payment) => {
        this.props.togglePaypalCheckout(false);
        this.props.paypalCheckout(payment);
        this.setState({ spend: '' });
    };

    onCancel = (data) => {
        console.log('************cancelled!', data);
        EventBus.publish("error", "The payment was cancelled!");
    };

    onError = (err) => {
        console.log("*****Error!", err);
        EventBus.publish("error", "Error!");
    };

    render() {
        let { buyTEXHistory, isTEXHistory, lightMode, isPaypal } = this.props;
        let { spend, calculate, priceStats, paypal, isRerender } = this.state;
        let { env, currency, style, client } = paypal;
        console.log("**********isPaypal::", isPaypal);
        return (
            <div className={`${lightMode ? 'light' : 'dark'}`}>
                <div className="landingkeep buy-tex">
                    <Navbar {...this.props} />
                    <section className="keep-keep">
                        <div className="buy-tex-container">
                            <div className="row keep-main">
                                <div className="col-xl-8 col-lg-7">
                                    <div className="buy-tex-sec">
                                        <div className="top-area">
                                            <ul className="coin-blance-area">
                                                <li className="coin-img-area title"><img className="coin-img mr-2" src={require("../../static/images/icons/tex.png")} alt="" />TEX/USD</li>
                                                <li></li>
                                                <li>Price <br /><NumberFormat value={priceStats['c'] || 0} coin={'TEX'} /></li>
                                                <li>Change <br /> <span className="greens">{parseFloat((((priceStats['c'] - priceStats['o']) / priceStats['c']) || 0) * 100).toFixed(2)} %</span></li>
                                                <li>24h Low <br /> <NumberFormat value={priceStats['l'] || 0} coin={'TEX'} /></li>
                                                <li>24h High <br /><NumberFormat value={priceStats['h'] || 0} coin={'TEX'} /></li>
                                                {/* <li>24h Volume<br /> 23142.72 BTC</li> */}
                                            </ul>
                                        </div>

                                        <div className="buy-tex-graph">
                                            <CanvasJSChart options={chartData} onRef={ref => this.chart = ref} />
                                        </div>
                                    </div>
                                </div>

                                <div className="col-xl-4 col-lg-5 col-md-12 col-sm-12 col-offset-2 form-main-back">
                                    <div className="form-main-back">
                                        <h1 className="text-center">Buy TEX Silver</h1>
                                        <form className="main-form-keep" onSubmit={this.submitValue} >
                                            <div class="form-group first-feild">
                                                <img src={require("../../static/images/main-slider/USD.png")} className="main-feild-image" alt="" />
                                                <label for="formGroupExampleInput">Spend</label>
                                                <input type="text" class="form-control" id="formGroupExampleInput2" placeholder="Enter USD Amount" value={spend} onChange={(e) => this.setState({ spend: e.target.value })} />
                                            </div>
                                            <div class="form-group first-feild">
                                                <img src={require("../../static/images/main-slider/TEX.png")} className="main-feild-image" alt="" />
                                                <label for="formGroupExampleInput">Receive</label>
                                                <input type="text" class="form-control" id="formGroupExampleInput2" value={(calculate != '') ? (parseFloat(spend / calculate)).toFixed(5) : '0.00000'} />
                                            </div>
                                            <div className="price-text"> <span>Price</span> <span className="float-right">1 TEX ≈ {(calculate == '') ? '-' : parseFloat(calculate).toFixed(5)} USDT</span> </div>
                                            <div className="paypal-btn">
                                                {isRerender &&
                                                    <Fragment>
                                                        {isPaypal
                                                            ? <PaypalExpressBtn style={style} env={env} client={client} currency={currency} total={spend} onError={this.onError} onSuccess={this.onSuccess} onCancel={this.onCancel} />
                                                            : <AppLoader message="Payment in progress..." />
                                                        }
                                                    </Fragment>
                                                }
                                                {/* <PaypalExpressBtn style={style} env={env} client={client} currency={currency} total={spend} onError={this.onError} onSuccess={this.onSuccess} onCancel={this.onCancel} /> */}
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>

                            {/* DEPOSIT HISTORY TABLE */}
                            <div className="row">
                                <div className="col-12">
                                    <div className="activaty-sec">
                                        <div class="activaty-inner">
                                            <div className="activaty-active">
                                                <a href="#" className="active">DEPOSIT HISTORY</a>
                                            </div>
                                            <div class="balance-table">
                                                <div class="table-responsive">
                                                    {isTEXHistory ? (
                                                        <table class="table table-clr table-striped">
                                                            <thead className="main-color">
                                                                <tr style={{ lineHeight: '0.7rem' }}>
                                                                    <th scope="col">DATE <span class="icn-box"></span></th>
                                                                    <th scope="col">PRICE(TEX) <span class="icn-box"></span></th>
                                                                    <th scope="col">AMOUNT(USDT)</th>
                                                                    <th scope="col">STATUS</th>
                                                                    <th scope="col">TOTAL</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {buyTEXHistory.length > 0
                                                                    ?
                                                                    <Fragment>
                                                                        {buyTEXHistory.slice(0, this.state.currentItems).map((data) => {
                                                                            return (
                                                                                <tr>
                                                                                    <td>{moment(data["createdAt"]).format("ll")}</td>
                                                                                    <td>{(data["amount"] / data['texTokens']).toFixed(4)}</td>
                                                                                    <td>{data["amount"]}</td>
                                                                                    <td>{data["status"]}</td>
                                                                                    <td>{parseFloat(data["texTokens"]).toFixed(4)}</td>
                                                                                </tr>
                                                                            );
                                                                        })}
                                                                        <tr>
                                                                            {buyTEXHistory.length > this.state.currentItems ?
                                                                                <td colSpan={7}>
                                                                                    <button type="button" class="loadmore" onClick={() => this.LoadMoreItems()}>Load More</button>
                                                                                </td>
                                                                                :
                                                                                <td colSpan={7}>
                                                                                    <button type="button" class="loadmore" >No More Data</button>
                                                                                </td>
                                                                            }
                                                                        </tr>
                                                                    </Fragment>
                                                                    :
                                                                    <tr>
                                                                        <td colSpan={7}>
                                                                            <p className="text-white">No Data Found!</p>
                                                                        </td>
                                                                    </tr>
                                                                }
                                                            </tbody>
                                                        </table>
                                                    ) : (<AppLoader />)}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </section>
                </div>
            </div>
        )
    }
}

const mapStateToProps = ({ TEX, Auth }) => {
    let { lightMode } = Auth;
    let { isPaypal, isTEXHistory, buyTEXHistory, graphTexData, priceTexData } = TEX;
    return { lightMode, isPaypal, isTEXHistory, buyTEXHistory, graphTexData, priceTexData };
};
const mapDispatchToProps = { buyTEXSockets, paypalCheckout, togglePaypalCheckout, toggleTexHistory, myPage };

export default connect(mapStateToProps, mapDispatchToProps)(BuyTEX);