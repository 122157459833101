import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import OnImagesLoaded from 'react-on-images-loaded';
import React, { Component, Fragment } from 'react';
import TextField from '@material-ui/core/TextField';
import { HashLink } from 'react-router-hash-link';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';

import './index.css';
import MainNavbar from '../../components/MainNavbar';
import NumberFormat from '../../components/NumberFormat';
import { verifyWithdrawal } from '../../store/actions/Wallet';
import { verifyEmail, kycLink, toggleAuthModal, loginSms, verifySmsLogin, loginVerifyGoogle } from '../../store/actions/Auth';


class Landing extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoader: true,
      bgTrans: true,
      enabled: props.userAuth['enabled'],
      selected: props.userAuth['enabled'] == 'sms' || props.userAuth['enabled'] == 'both' ? 'sms' : '2fa',
      smsKey: '',
      code: { first: '', second: '', third: '', fourth: '', fifth: '', sixth: '' }
    };
  };

  componentDidMount() {
    this.setState({ bgTrans: true });
    let { location } = this.props;
    if (location.pathname.match('verify_withdrawal')) {
      let data = location.pathname.split('/')[--location.pathname.split('/').length];
      this.props.verifyWithdrawal({ data, history: this.props.history });
    }
    if (location.pathname.match('verifyEmail')) {
      let data = location.pathname.split('/')[--location.pathname.split('/').length];
      this.props.verifyEmail({ data, history: this.props.history });
      this.props.history.push('/');
    }
    if (location.pathname.match('kyc_link')) {
      let data = location.pathname.split('/')[--location.pathname.split('/').length];
      this.props.kycLink({ data, history: this.props.history });
      this.props.history.push('/');
    }
  };

  componentWillReceiveProps({ userAuth, isUserAuthModal }) {
    if (isUserAuthModal) {
      let selected = userAuth['enabled'] == 'sms' || userAuth['enabled'] == 'both' ? 'sms' : '2fa';
      this.setState({ enabled: userAuth['enabled'], selected });
    }
  }

  sendSms = () => {
    let { id } = this.props.userAuth;
    this.props.loginSms(id);
  }

  submitSms = () => {
    let { smsKey } = this.state;
    let { history } = this.props;
    let { id } = this.props.userAuth;

    let data = { smsKey };
    this.props.verifySmsLogin({ data, id, history });
  }

  changeTab = (selected) => this.setState({ selected });

  handleChangedCode = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    let { code } = this.state;
    code[name] = value;
    this.setState({ code });

    let input;
    if (name === 'first') input = document.getElementById('second');
    if (name === 'second') input = document.getElementById('third');
    if (name === 'third') input = document.getElementById('fourth');
    if (name === 'fourth') input = document.getElementById('fifth');
    if (name === 'fifth') input = document.getElementById('sixth');
    if (input) {
      input.focus();
      input.select();
    }
    if (name === 'sixth') {
      document.getElementById('sixth').blur();
      let { history } = this.props;
      let { id } = this.props.userAuth;
      let token_code = code.first + code.second + code.third + code.fourth + code.fifth + code.sixth;
      this.props.loginVerifyGoogle({ data: { token_code }, id, history });
      setTimeout(() => {
        if (document.getElementById('first')) document.getElementById('first').focus();
        this.setState({ code: { first: '', second: '', third: '', fourth: '', fifth: '', sixth: '' } })
      }, 1500)
    }
  }

  runAfterImagesLoaded = () => {
    setTimeout(() => this.setState({ isLoader: false }), 2000);
  }

  render() {
    let { auth, isUserAuthModal, loading, landingBannerData, landingTableData } = this.props;
    let { isLoader, enabled, selected, smsKey, code } = this.state;

    return (
      <div className="landingkeep">
        <OnImagesLoaded onLoaded={this.runAfterImagesLoaded}>
          <MainNavbar  {...this.props} />
          {isLoader
            ? <div className="preloader light"></div>
            : <Fragment>
              <section className="banner-section" style={{ backgroundImage: `url(${require("../../static/images/main-slider/bg-min.png")})` }}>
                <div className="auto-container-fluid">
                  <div className="row">
                    <div className="col-lg-7 col-md-12 col-sm-12">
                      <div className="content-box">
                        <h1>Introducing the <br /> <span>Blockchain</span> Ecosystem</h1>
                        <div className="text">Trading Performance | Protection | Privacy</div>
                        <div className="link-box">
                          <Link to="" className="theme-btn btn-style-one text-white">CREATE A WALLET </Link>
                          {/* <a href="https://www.cryptoexchangesoftware.net/" target="_blank" className="theme-btn btn-style-one">get in touch</a> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </section>

              <section className="coins-sec">
                <div className="auto-container">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="sec-title text-center">
                      </div>
                      <div className="coins-box">
                        {
                          Object.values(landingBannerData).map(data => {
                            return (
                              <div className="coin-inner text-center">
                                <div className="icon-area">
                                  <figure><img src={require(`../../static/images/icons/${data['symbol']}.png`)} alt="" /></figure>
                                </div>
                                <div className="text-box">
                                  <h3>{data['name']} <br /> ({data['symbol']})</h3>
                                  <div className="payment"><NumberFormat value={data['close']} decimals={2} /></div>
                                  <div className={`status-perc ${data['change'] > 0 ? 'green' : 'red'}`}>
                                    <i className={`fa ${data['change'] > 0 ? 'fa-caret-up' : 'fa-caret-down'}`}></i> {data['change']}%
                            </div>
                                </div>
                              </div>
                            )
                          })
                        }
                        <>
                          {/* <div className="coin-inner text-center">
                    <div className="icon-area">
                      <figure><img src={require("../../static/images/icons/ripple.png")} alt="" /></figure>
                    </div>
                    <div className="text-box">
                      <h3>Ripple (XRP)</h3>
                      <div className="payment">$3.07293</div>
                      <div className="status-perc red">
                        <i className="fa fa-caret-down"></i>-0.28%
                      </div>
                    </div>
                  </div>

                  <div className="coin-inner text-center">
                    <div className="icon-area">
                      <figure><img src={require("../../static/images/icons/ethurem.png")} alt="" /></figure>
                    </div>
                    <div className="text-box">
                      <h3>Ethereum (ETH)</h3>
                      <div className="payment">$986.88</div>
                      <div className="status-perc green">
                        <i className="fa fa-caret-up"></i>0.47%
                      </div>
                    </div>
                  </div>

                  <div className="coin-inner text-center">
                    <div className="icon-area">
                      <figure><img src={require("../../static/images/icons/iota.png")} alt="" /></figure>
                    </div>
                    <div className="text-box">
                      <h3>IOTA (MIOTA)</h3>
                      <div className="payment">$17133.2</div>
                      <div className="status-perc red">
                        <i className="fa fa-caret-down"></i>-1.87%
                      </div>
                    </div>
                  </div>

                  <div className="coin-inner text-center">
                    <div className="icon-area">
                      <figure><img src={require("../../static/images/icons/nem.png")} alt="" /></figure>
                    </div>
                    <div className="text-box">
                      <h3>NEM (XEM)</h3>
                      <div className="payment">$1.60624</div>
                      <div className="status-perc green">
                        <i className="fa fa-caret-up"></i>0.21%
                      </div>
                    </div>
                  </div> */}
                        </>
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              <section className="why-choose-us">
                <div className="auto-container">
                  <div className="row">
                    <div className="service-outer col-lg-7 col-md-12 col-sm-12">
                      <div className="service-inner">
                        <div className="icon-area" >
                          <figure><img src={require("../../static/images/icons/perfomance.png")} alt="" /></figure>
                        </div>
                        <div className="text-box">
                          <div className="title-area">
                            <h2>PERFORMANCE</h2>
                          </div>
                          <div className="text">Buy, Sell, and Trade using KEEP Exchange. Get in, trade, cash out, repeat. Buy Bitcoin with your credit and debit card from your wallet.</div>
                        </div>
                      </div>
                    </div>

                    <div className="service-outer col-lg-5 col-md-12 col-sm-12">
                      <div className="service-inner">
                        <div className="icon-area">
                          <figure><img src={require("../../static/images/icons/perfomance-1.png")} alt="" /></figure>
                        </div>

                        <div className="icon-area">
                          <figure><img src={require("../../static/images/icons/perfomance-2.png")} alt="" /></figure>
                        </div>
                        {/* <div className="icon-bottom">
                    <figure><img src={require("../../static/images/icons/perfomance-3.png")} alt="" /></figure>
                  </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              <section className="account-sec" style={{ backgroundImage: `url(${require("../../static/images/background/1.png")})` }}>
                <div className="container-fluid">
                  <div className="row">
                    <div className="image-column col-lg-6 col-md-12 col-sm-12">
                      <figure><img src={require("../../static/images/resource/macbook.png")} alt="" /></figure>
                    </div>
                    <div className="content-column col-lg-6 col-md-12 col-sm-12">
                      <div className="inner-clomun">
                        <div className="sec-title">
                          <h2>Trade On the Cryptocurrency Derivatives Exchange with the Best Perpetual Contracts</h2>
                        </div>
                        <div className="text">We listen, care, and improve to provide the best possible trading experience</div>
                        <div className="link-box">
                          {!auth && <Link to="/signup" className="theme-btn btn-style-one text-white create-account">Create account within seconds</Link>}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              <section className="table-sec">
                <div className="auto-container">
                  <div className="row">
                    <div className="col-12">
                      <div className="sec-title centered">
                        <h2>Realtime Cryptocurrency</h2>
                        <div className="icon-title">
                          <figure className="logo-cube"><img src={require("../../static/images/wallet-logo-box.png")} alt="" /></figure>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="table-responsive-lg">
                    <table className="table table-hover table-striped">
                      <thead className="thead-dark">
                        <tr>
                          <th scope="col ml-2">Coin Name</th>
                          <th scope="col"><span className="">Price</span></th>
                          <th scope="col"><span className="">24-hrs Volume</span> </th>
                          <th scope="col"><span className="">24-hrs Change</span> </th>
                        </tr>
                      </thead>
                      <tbody className="tbody-dark">
                        {Object.values(landingTableData).map(data => {
                          return (
                            <tr>
                              <th scope="row" className="first-ch"><img className='landing-table-img' src={require(`../../static/images/icons/${data['symbol']}.png`)} alt="" />{data['name']}</th>
                              <td className='text-dark'>$<NumberFormat value={data['close']} decimals={2} /></td>
                              <td className='text-dark'><NumberFormat value={data['volume']} decimals={2} /> {data['symbol']}</td>
                              <td className='text-dark'><NumberFormat value={data['change']} decimals={2} /> %</td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </section>

              <section className="countery-sec" style={{ backgroundImage: `url(${require("../../static/images/background/2.png")})` }}>
                <div className="auto-container">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="sec-title centered">
                        <h4>Countries</h4>
                        <h2>100,000 Registered Users and Counting</h2>
                        <div className="text">Change Your Virtual Location at any time. Switch between countries in second.</div>
                      </div>

                      <div className="map-area">
                        <figure><img src={require("../../static/images/map.png")} alt="" /></figure>
                      </div>
                    </div>

                  </div>
                </div>
              </section>

              <section id='about' className="monyback-sec">
                <div className="auto-container">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="sec-title centered">
                        <h2>KEEP in touch. Stay in touch.</h2>
                      </div>
                    </div>
                    <div className="box-column">
                      <div className="row clearfix">
                        <div className="col-lg-4 col-md-6 col-sm-12">
                          <div className="monyback-box">
                            <div className="icon-num">1</div>
                            <div className="icon-area">
                              <figure><img src={require("../../static/images/icons/__24_7.png")} alt="" width="74px" /></figure>
                            </div>
                            <div className="text-area">
                              <h4>24 / 7 Support</h4>
                              <div className="text">Got a problem? Just get in touch. Our support team is available 24/7.</div>
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-12">
                          <div className="monyback-box">
                            <div className="icon-num">2</div>
                            <div className="icon-area">
                              <figure><img src={require("../../static/images/icons/__Alliance.png")} alt="" width="74px" /></figure>
                            </div>
                            <div className="text-area">
                              <h4>Community</h4>
                              <div className="text">KEEP Blockchain Ecosystem is a global alliance. Join the discussion in our worldwide gig economy Ecosystem, powered by the Blockchain.</div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12">
                          <div className="monyback-box">
                            <div className="icon-num">3</div>
                            <div className="icon-area">
                              <figure><img src={require("../../static/images/icons/__Global_Careers.png")} alt="" width="74px" /></figure>
                            </div>
                            <div className="text-area">
                              <h4>Careers</h4>
                              <div className="text">Help build the future of technology. Start your new career at KEEP Blockchain Ecosystem.</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              <footer className="main-footer" style={{ backgroundImage: `url(${require("../../static/images/background/3.png")})` }}>
                <div className="auto-container">
                  <div className="row">
                    <div className="col-lg-12 col-md-12">
                      <ul className="list-menu">
                        <li><a href="#">Company</a></li>
                        <li><Link className='mr-4' to='/'>Home  </Link></li>
                        <li><HashLink className='mr-4' smooth to='/#about'>About</HashLink></li>
                        <li><a href="#">Contact Us</a></li>
                      </ul>
                      <div className="footer-area text-center">
                        <img className="footer-img mb-4" src={require("../../static/images/TEX-title.jpeg")} alt="" title="" />
                        {/* <div className="footer-logo mb-4"><a href="index.html"><img src={require("../../static/images/footer-logo.png")} alt="" /></a></div> */}
                        {/* <h4 className='text-white'>A Trusted Agent in an untrusted world</h4> */}
                      </div>
                    </div>

                    <div className="col-lg-12 col-md-12 col-sm-12">
                      <div className="widgets-section">
                        <div className="row clearfix">
                          <div className="footer-column col-lg-4 col-md-6 col-sm-12">
                            <div className="footer-widget links-widget">
                              <h5 className="widget-title">Address</h5>
                              <div className="widget-content">
                                <p></p>
                              </div>
                            </div>
                          </div>
                          <div className="footer-column col-lg-4 col-md-6 col-sm-12">
                            <div className="footer-widget links-widget">
                              <h5 className="widget-title">Get IN Touch</h5>
                              <div className="widget-content">
                                <ul className="list">
                                  <p>info@theellipsis.exchange</p>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div className="footer-column col-lg-4 col-md-6 col-sm-12">
                            <div className="footer-widget links-widget">
                              <h5 className="widget-title">Follow On Us:</h5>
                              <div className="widget-content">
                                <ul className="list-address">
                                  <li><a href="#"></a><img className="footer-img mb-4" src={require("../../static/images/fb.png")} alt="" title="" /></li>
                                  <li><a href="#"></a><img className="footer-img mb-4" src={require("../../static/images/tw.png")} alt="" title="" /></li>
                                  <li><a href="#"></a><img className="footer-img mb-4" src={require("../../static/images/linkedin.png")} alt="" title="" /></li>
                                  <li><a href="#"></a><img className="footer-img mb-4" src={require("../../static/images/yt.png")} alt="" title="" /></li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div className="bottom-footer col-lg-12 col-md-12 col-sm-12">
                            <div className="bottom-footer-area">
                              <p>© 2021 Copyrights by KEEP Blockchain Ecosystem</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </footer>


              {/* ---------------USER AUTHENTICATION MODAL--------------- */}
              <Modal isOpen={isUserAuthModal} toggle={this.props.toggleAuthModal} className='user-auth-modal'>
                <ModalHeader toggle={this.props.toggleAuthModal}>
                  <a href='#'><img className='modal-logo' src={require('../../static/images/wallet-logo.png')} alt='modal-logo' /></a>
                </ModalHeader>
                <ModalBody>
                  {enabled == 'both' ?
                    <div className="row">
                      <a className="col-5" onClick={() => this.changeTab('sms')}><p className={selected === 'sms' ? "selected-auth-title" : "auth-title"}>SMS AUTH</p></a>
                      <div className="col-2 modal-divider"> <div className="modal-divider-line"></div> </div>
                      <a className="col-5" onClick={() => this.changeTab('2fa')}><p className={selected === '2fa' ? "selected-auth-title" : "auth-title"}>GOOGLE AUTH</p></a>
                    </div>
                    : (enabled == '2fa') ?
                      <div className="row">
                        <a className="col-12"><p className="auth-title user-single-auth-title">GOOGLE AUTHENTICATION</p></a>
                      </div>
                      : (enabled == 'sms') ?
                        <div className="row">
                          <a className="col-12"><p className="auth-title user-single-auth-title">SMS AUTHENTICATION</p></a>
                        </div>
                        : null
                  }
                  <hr />

                  {selected == 'sms'
                    ? <Fragment>
                      <ValidatorForm className='sms-validator-form row my-4'>
                        <div className="col-md-8 col-sm-12 auth-area">
                          <TextField
                            fullWidth
                            name="smsKey"
                            type='number'
                            value={smsKey}
                            variant="outlined"
                            id='standard-full-width'
                            className='sms-auth-input-field mt-4'
                            placeholder="SMS Authentication Code"
                            onChange={(e) => this.setState({ smsKey: e.target.value })}
                          />
                        </div>
                        <div className="col-md-4 col-sm-12 auth-area">
                          <button type="button" className="theme-btn btn-style-four sms-auth-btn mt-4" onClick={this.sendSms}>SEND SMS</button>
                        </div>
                      </ValidatorForm>
                    </Fragment>
                    : null
                  }

                  {selected == '2fa'
                    ? <Fragment>
                      <ValidatorForm className="google-validator-form row my-5">
                        <div className="col-12 auth-area">
                          <TextField
                            id='first'
                            name='first'
                            type='number'
                            placeholder='-'
                            value={code.first}
                            className='google-input-field mx-2'
                            onChange={this.handleChangedCode}
                            validators={['required']}
                            errorMessages={['Code is Required']}
                          />
                          <TextField
                            id='second'
                            name='second'
                            type='number'
                            placeholder='-'
                            value={code.second}
                            className='google-input-field mx-2'
                            onChange={this.handleChangedCode}
                            validators={['required']}
                            errorMessages={['Code is Required']}
                          />
                          <TextField
                            id='third'
                            name='third'
                            type='number'
                            placeholder='-'
                            value={code.third}
                            className='google-input-field mx-2'
                            onChange={this.handleChangedCode}
                            validators={['required']}
                            errorMessages={['Code is Required']}
                          />
                          <TextField
                            id='fourth'
                            name='fourth'
                            type='number'
                            placeholder='-'
                            value={code.fourth}
                            className='google-input-field mx-2'
                            onChange={this.handleChangedCode}
                            validators={['required']}
                            errorMessages={['Code is Required']}
                          />
                          <TextField
                            id='fifth'
                            name='fifth'
                            type='number'
                            placeholder='-'
                            value={code.fifth}
                            className='google-input-field mx-2'
                            onChange={this.handleChangedCode}
                            validators={['required']}
                            errorMessages={['Code is Required']}
                          />
                          <TextField
                            id='sixth'
                            name='sixth'
                            type='number'
                            placeholder='-'
                            value={code.sixth}
                            className='google-input-field mx-2'
                            onChange={this.handleChangedCode}
                            validators={['required']}
                            errorMessages={['Code is Required']}
                          />
                        </div>
                      </ValidatorForm>
                    </Fragment>
                    : null
                  }
                </ModalBody>
                {selected == 'sms' &&
                  <ModalFooter className='mb-4'>
                    <div>
                      <button type="button" className="theme-btn btn-style-one user-auth-btn px-2" onClick={this.submitSms}>
                        {!loading ? 'SUBMIT' : <i className="fa fa-spinner fa-spin fa-1x fa-fw" />}
                      </button>
                    </div>
                  </ModalFooter>
                }
              </Modal>

            </Fragment>
          }
        </OnImagesLoaded>
      </div>
    )
  }
}

const mapStateToProps = ({ Auth }) => {
  let { auth, userAuth, isUserAuthModal, loading, landingBannerData, landingTableData } = Auth;
  return { auth, userAuth, isUserAuthModal, loading, landingBannerData, landingTableData };
};
const mapDispatchToProps = { verifyWithdrawal, verifyEmail, kycLink, toggleAuthModal, loginSms, verifySmsLogin, loginVerifyGoogle };

export default connect(mapStateToProps, mapDispatchToProps)(Landing);