import React, { Component } from 'react';
import Switch from "react-switch";
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

import { userUrl } from '../../store/config';
import { logoutUser } from '../../store/actions/Auth';
import { changeMode } from '../../store/actions/Trading';

import "./index.css";

function Header({ changeMode, lightMode, location, logoutUser }) {
  let { pathname } = location;
  const { logout } = useAuth0();

  const changeModeFunc = (checked) => changeMode(checked);

  const logoutButton = () => {
    logoutUser();
    logout({ returnTo: userUrl });
  }


  return (
    <header className="inner-header header-style-one">
      <div className="header-upper">
        <div className="inner-container">
          <div className="container-fluid clearfix">
            <div className="nav-inner">
              <div className="logo-outer">
                <div className="logo row mb-2">
                  <Link to='/'>
                    <img className="navbar-logo-img" src={require('../../static/images/TEX-title.jpeg')} alt="Keep Exchange" />
                  </Link>
                </div>
              </div>
              <div className="nav-outer clearfix">
                <nav className="inner-menu navbar-expand-md navbar-light">
                  <div className="navbar-header">
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                      <span><i class="fas fa-bars"></i></span>
                    </button>
                  </div>
                  <div className="collapse navbar-collapse clearfix" id="navbarSupportedContent">
                    <ul className="navigation clearfix">
                      <li className={`${pathname.match('trading') && 'current'}`}> <Link to='/trading'>Trading</Link> </li>
                      <li className={`${pathname.match('buyTex') && 'current'}`}><Link to='/buyTex'>Buy TEX</Link> </li>
                      <li className={`${pathname.match('wallet') && 'current'}`}><Link to='/wallet'>Wallet</Link></li>
                    </ul>
                  </div>
                </nav>
              </div>
              <div className="right-area">
                <ul className="right-box">
                  <li>
                    <Switch
                      onChange={(e) => changeModeFunc(e)}
                      checked={lightMode}
                      onColor="#919191"
                      onHandleColor="#5c0b49"
                      handleDiameter={20}
                      uncheckedIcon={false}
                      checkedIcon={false}
                      boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                      activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                      height={15}
                      width={34}
                      className="react-switch"
                      id="material-switch" />
                  </li>
                  {/* <li className="current dropdown"><span className="fa fa-sort-down"></span><a href="#">Language</a>
                      <ul>
                        <li><a href="#">English</a></li>
                        <li><a href="#">Urdu</a></li>
                        <li><a href="#">Arabic</a></li>
                      </ul>
                    </li> */}
                  <li className={`${pathname.match('myPage') && 'current'}`}><Link to='/myPage'>My Page</Link></li>
                  <li><a onClick={logoutButton}>Logout</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}

const mapStateToProps = ({ Auth, Trading }) => {
  let { lightMode } = Auth;
  let { } = Trading;
  return { lightMode };
};
const mapDispatchToProps = { changeMode, logoutUser };

export default connect(mapStateToProps, mapDispatchToProps)(Header);